import React, {Component} from 'react'
import {
    Menu,
    Button,
    Input,
    Table,
    Label,
    Container,
    Embed,
    Statistic,
    Form,
    Segment,
    Header,
    Icon,
    Image,
    Grid,
    List,
    Item,
    Divider,
    Modal,
    Message
} from 'semantic-ui-react'
import {NavLink, Link} from 'react-router-dom'
import Slider from "react-slick/lib";
const tt1 = '/img/locationServices.png'

const communicationImages = [
    require('../../../../img/chat.PNG'),
    require('../../../../img/sideMenu.PNG'),
    require('../../../../img/directMessage.PNG'),
]

const createSiteImages = [
    require('../../../../img/createSite.PNG'),
    require('../../../../img/findLocationKeyboard.PNG'),
    require('../../../../img/manageGeofence.PNG'),
    require('../../../../img/joinSite.PNG'),

]

const clientImages = [
    require('../../../../img/clientTexting.PNG'),
    require('../../../../img/clientChat.PNG')

]


const taskImages = [
    require('../../../../img/createTask.PNG'),
    require('../../../../img/taskList.PNG')
]

const manageImages = [
    require('../../../../img/manageSite.PNG'),
    require('../../../../img/createCrew.PNG'),
    require('../../../../img/manageRoles.PNG'),

]

export default class DesktopLayout extends Component {


  constructor(props) {
      super(props)


      this.state = {
          //mobile:props.mobile
      }

  }


  render(){

    let mobile = this.props.mobile
    return (
        <div>

            <Segment
                textAlign='center'
                style={{ backgroundColor:'#fff', minHeight: 700, padding: '0em 0em' }}
                vertical
            >
                <Grid columns={2}>
                    {/*<Grid.Column>*/}
                    {/*    <Image src={require('../../../../img/joinSite.PNG')} style={{maxWidth:350,margin:'auto'}}/>*/}
                    {/*</Grid.Column>*/}
                    <Grid.Column style={{margin:'auto'}}>
                        <Button color={'blue'} circular icon='pin' size={'massive'} style={{display:'block',float:'right',marginLeft:'2em'}} />

                        <span style={{textAlign:'right',float:'right', fontSize:'2.5em',color:'#1d1d1d',fontWeight:'bold'}}>It all starts with a Site.</span>

                        <Header color={'black'} style={{fontWeight:'normal',margin:'auto',float:'right',marginTop:'1em',alignSelf:'center',maxWidth:400,textAlign:'right'}} as={'h3'} content={`Each Site has a geofence to make check-in as easy as opening the Skilled On Site app.`} />

                    </Grid.Column>

                    <Grid.Column>
                        <Slider {...this.props.settings} arrows={false}>
                            {
                                createSiteImages.map((image)=>{
                                    return <div><Image size={'medium'} centered={true} src={image} style={{maxWidth:400}}/></div>
                                })
                            }
                        </Slider>
                    </Grid.Column>

                </Grid>
            </Segment>

            <Segment
                textAlign='center'
                style={{ backgroundColor:'#fff', minHeight: 700, padding: '0em 0em' }}
                vertical
            >
                <Grid columns={2}>

                    <Grid.Column>
                        <Slider {...this.props.settings} arrows={false}>

                            {
                                communicationImages.map((image)=>{
                                    return <div><Image size={'medium'} centered={true} src={image} style={{maxWidth:400}}/></div>
                                })
                            }
                        </Slider>
                    </Grid.Column>
                    <Grid.Column style={{margin:'auto'}}>
                        <Button color={'blue'} circular icon='comments outline' size={'massive'} style={{display:'block',float:'left',marginRight:'2em'}} />

                        <span style={{textAlign:'left',float:'left', fontSize:'2.5em',color:'#1d1d1d',fontWeight:'bold'}}>Communicate in real-time.</span>

                        <Header color={'black'} style={{fontWeight:'normal',margin:'auto',float:'left',marginTop:'1em',alignSelf:'center',maxWidth:400,textAlign:'left'}} as={'h3'} content={`Get information to all your crews at once or open a direct message to chat with a specific crew member.`} />

                    </Grid.Column>

                </Grid>
            </Segment>


            <Segment
                textAlign='center'
                style={{ backgroundColor:'#fff', minHeight: 700, padding: '0em 0em' }}
                vertical
            >
                <Grid columns={2}>
                    {/*<Grid.Column>*/}
                    {/*    <Image src={require('../../../../img/joinSite.PNG')} style={{maxWidth:350,margin:'auto'}}/>*/}
                    {/*</Grid.Column>*/}
                    <Grid.Column style={{margin:'auto'}}>
                        <Button color={'blue'} circular icon='tasks' size={'massive'} style={{display:'block',float:'right',marginLeft:'2em'}} />

                        <span style={{textAlign:'right',float:'right', fontSize:'2.5em',color:'#1d1d1d',fontWeight:'bold'}}>Keep your crews on task.</span>

                        <Header color={'black'} style={{fontWeight:'normal',margin:'auto',float:'right',marginTop:'1em',alignSelf:'center',maxWidth:400,textAlign:'right'}} as={'h3'} content={`Create and manage a Site punchlist to keep crews on task and monitor progress.`} />

                    </Grid.Column>

                    <Grid.Column>
                        <Slider {...this.props.settings} arrows={false}>
                            {
                                taskImages.map((image)=>{
                                    return <div><Image size={'medium'} centered={true} src={image} style={{maxWidth:400}}/></div>
                                })
                            }
                        </Slider>
                    </Grid.Column>

                </Grid>
            </Segment>

            <Segment
                textAlign='center'
                style={{ backgroundColor:'#fff', minHeight: 700, padding: '0em 0em' }}
                vertical
            >
                <Grid columns={2}>

                    <Grid.Column>
                        <Slider {...this.props.settings} arrows={false}>

                            {
                                manageImages.map((image)=>{
                                    return <div><Image size={'medium'} centered={true} src={image} style={{maxWidth:400}}/></div>
                                })
                            }
                        </Slider>
                    </Grid.Column>
                    <Grid.Column style={{margin:'auto'}}>

                        <Button color={'blue'} circular icon='lock' size={'massive'} style={{display:'block',float:'left',marginRight:'2em'}} />

                        <span style={{textAlign:'left',float:'left', fontSize:'2.5em',color:'#1d1d1d',fontWeight:'bold'}}>Manage crews and permissions.</span>

                        <Header color={'black'} style={{fontWeight:'normal',margin:'auto',float:'left',marginTop:'1em',alignSelf:'center',maxWidth:400,textAlign:'left'}} as={'h3'} content={`Update your Site crews, member permissions, and punchlist items with a tap of your finger. Anywhere. Anytime.`} />

                    </Grid.Column>

                </Grid>
            </Segment>

            <Segment
                textAlign='center'
                style={{ backgroundColor:'#fff', minHeight: 700, padding: '0em 0em' }}
                vertical
            >
                <Grid columns={2}>
                    {/*<Grid.Column>*/}
                    {/*    <Image src={require('../../../../img/joinSite.PNG')} style={{maxWidth:350,margin:'auto'}}/>*/}
                    {/*</Grid.Column>*/}
                    <Grid.Column style={{margin:'auto'}}>
                        <Button color={'blue'} circular icon='handshake' size={'massive'} style={{display:'block',float:'right',marginLeft:'2em'}} />

                        <span style={{textAlign:'right',float:'right', fontSize:'2.5em',color:'#1d1d1d',fontWeight:'bold'}}>Collaborate with your client.</span>

                        <Header color={'black'} style={{fontWeight:'normal',margin:'auto',float:'right',marginTop:'1em',alignSelf:'center',maxWidth:400,textAlign:'right'}} as={'h3'} content={`Make it easy to keep your clients up-to-date with progress and get their input in real-time.`} />

                    </Grid.Column>

                    <Grid.Column>
                        <Slider {...this.props.settings} arrows={false}>
                            {
                                clientImages.map((image)=>{
                                    return <div><Image size={'medium'} centered={true} src={image} style={{maxWidth:400}}/></div>
                                })
                            }
                        </Slider>
                    </Grid.Column>

                </Grid>
            </Segment>

            <Segment style={{ backgroundColor:'#fff', padding: '4em 0em' }} vertical>
                <Grid columns={2} centered divided relaxed>
                    <Grid.Column width={4} centered>
                        {/*<Header style={{textAlign:'center',alignSelf:'center'}} as={'h2'} content={`Get to work for just`}/>*/}
                        {/*<Statistic color={'green'} style={{alignSelf:'center',display:'block',textAlign:'center'}}>*/}
                        {/*    <Statistic.Value style={{textAlign:'center',alignSelf:'center'}}>$250</Statistic.Value>*/}
                        {/*    <Statistic.Label style={{textAlign:'center',alignSelf:'center'}}>per Site</Statistic.Label>*/}
                        {/*</Statistic>*/}
                        <div style={{textAlign:'center'}}>
                            <Header style={{marginTop:'2em',textAlign:'center'}} content={'Contact us to get started!'} as={'h2'} subheader={`We'll provide your team with free training and support to make on-boarding easy.`}/>
                            {/*<Button as={'a'} size={'massive'} href={'mailto:contact@skilledonsite.com'} style={{alignSelf:'center'}} color={'green'} content={'Get Started'} />*/}
                            <Modal open={this.props.showSalesModal} onOpen={this.props.onOpen} onClose={this.props.onClose} size={'tiny'} trigger={<Button size={'massive'} style={{alignSelf:'center'}} color={'green'} content={'Get Started'} />}>
                                <Modal.Header>Get Skilled On Site</Modal.Header>
                                <Modal.Content scrolling>

                                    <Modal.Description>

                                        {
                                            this.props.requestComplete ?
                                                <Message
                                                    header={`We'll be in touch!`}
                                                    content={'One of our team members will reach out to you shortly.'}

                                                />
                                                :
                                                <div>
                                                    <p>
                                                        Provide us with your contact information and we'll reach out<br/>to get you setup with Skilled On Site.
                                                    </p>
                                                    <Form>
                                                        <Form.Input
                                                            required
                                                            fluid
                                                            label='Full name'
                                                            placeholder='Full name'
                                                            onChange={this.props.onChangeName}
                                                            id='form-input-first-name'
                                                        />
                                                        <Form.Input
                                                            required
                                                            fluid
                                                            label='Email'
                                                            placeholder='Email'
                                                            onChange={this.props.onChangeEmail}
                                                        />
                                                        <Form.Input
                                                            required
                                                            fluid
                                                            label='Phone Number'
                                                            placeholder='Phone Number'
                                                            onChange={this.props.onChangePhone}
                                                        />
                                                    </Form>
                                                </div>
                                        }

                                    </Modal.Description>
                                </Modal.Content>
                                <Modal.Actions>
                                    {
                                        this.props.requestComplete ?
                                            <Button primary onClick={this.props.onClose}>
                                                Close
                                            </Button>
                                        :
                                            <Button primary onClick={this.props.submitForm}>
                                                Submit
                                            </Button>
                                    }
                                </Modal.Actions>
                            </Modal>
                            {/*<Button size={'massive'} onClick={()=>window.location='/register'} style={{alignSelf:'center'}} color={'green'} content={'Start Now!'} />*/}
                        </div>
                    </Grid.Column>
                    <Grid.Column width={10} centered>
                        <Grid columns={2}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Button color={'blue'} circular icon='tasks' size={'massive'} style={{display:'block',margin:'auto',cursor:'default'}} />
                                    <Header content={'Manage Punchlist Items'} style={{textAlign:'center'}} subheader={'Create and manage the tasks of your crews.'}/>
                                </Grid.Column>
                                <Grid.Column>
                                    <Button color={'blue'} circular icon='users' size={'massive'} style={{display:'block',margin:'auto',cursor:'default'}} />
                                    <Header content={'Unlimited Users'} style={{textAlign:'center'}} subheader={`Have a big job site? No problem. Invite as many people as you'd like to the Site.`}/>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Button color={'blue'} circular icon='comments outline' size={'massive'} style={{display:'block',margin:'auto',cursor:'default'}} />
                                    <Header content={'Streamlined Communication'} style={{textAlign:'center'}} subheader={'Get the answers to the questions you have when you need them.'}/>
                                </Grid.Column>
                                <Grid.Column>
                                    <Button color={'blue'} circular icon='map pin' size={'massive'} style={{display:'block',margin:'auto',cursor:'default'}} />
                                    <Header content={'Easy check-in'} style={{textAlign:'center'}} subheader={'Using device location we make it easy for crews to quickly join by just opening the app.'}/>

                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>

                    {/*<Grid.Row>*/}
                    {/*    <Grid.Column>*/}
                    {/*        <Button circular icon='law' size={'massive'} style={{display:'block',margin:'auto'}} />*/}
                    {/*        <Header content={'No contracts or setup fees.'} style={{textAlign:'center'}} subheader={'No contracts. Pay only when you create a new Site.'}/>*/}

                    {/*    </Grid.Column>*/}
                    {/*</Grid.Row>*/}
                </Grid>

                {/*<Grid container stackable verticalAlign='middle'>*/}
                {/*    <Grid.Row>*/}
                {/*        <Grid.Column width={8}>*/}
                {/*            /!*<Image src={require('../../../../img/skilled_logo_600x340.jpg')} size={'medium'} centered/>*!/*/}
                {/*            <Header style={{textAlign:'center',alignSelf:'center'}} as={'h2'} content={`Get to work for just`}/>*/}
                {/*            <Statistic color={'green'} style={{alignSelf:'center',display:'block',textAlign:'center'}}>*/}
                {/*                <Statistic.Value style={{textAlign:'center',alignSelf:'center'}}>$250</Statistic.Value>*/}
                {/*                <Statistic.Label style={{textAlign:'center',alignSelf:'center'}}>per Site</Statistic.Label>*/}
                {/*            </Statistic>*/}
                {/*            <div style={{textAlign:'center'}}>*/}
                {/*                <Header style={{marginTop:'2em',textAlign:'center'}} content={'Get your 1st Site free!'} as={'h3'} />*/}
                {/*                <Button size={'massive'} onClick={()=>window.location='/'} style={{alignSelf:'center'}} color={'green'} content={'Start Now!'} />*/}
                {/*            </div>*/}
                {/*        </Grid.Column>*/}
                {/*        <Grid.Column floated='left' width={8}>*/}


                {/*            /!*<List size={this.props.mobile ? 'medium' : 'large'} relaxed style={{maxWidth:600,margin:'auto',alignSelf:'center'}}>*!/*/}
                {/*            /!*    <List.Item style={{paddingLeft:10}}>*!/*/}
                {/*            /!*        <Icon name='checkmark' color={'green'} />*!/*/}

                {/*            /!*        <List.Content>*!/*/}
                {/*            /!*            <List.Header>Stream-lined communication</List.Header>*!/*/}
                {/*            /!*            <List.Description>*!/*/}
                {/*            /!*                Create crews to organize communication and tasks.*!/*/}
                {/*            /!*            </List.Description>*!/*/}
                {/*            /!*        </List.Content>*!/*/}
                {/*            /!*    </List.Item>*!/*/}
                {/*            /!*    <List.Item style={{paddingLeft:10}}>*!/*/}
                {/*            /!*        <Icon name='checkmark' color={'green'} />*!/*/}
                {/*            /!*        <List.Content>*!/*/}
                {/*            /!*            <List.Header>Real-time messaging and notifications</List.Header>*!/*/}
                {/*            /!*            <List.Description>*!/*/}
                {/*            /!*                Send messages to your crews and they'll receive push notifications.*!/*/}
                {/*            /!*            </List.Description>*!/*/}
                {/*            /!*        </List.Content>*!/*/}
                {/*            /!*    </List.Item>*!/*/}
                {/*            /!*    <List.Item style={{paddingLeft:10}}>*!/*/}
                {/*            /!*        <Icon name='checkmark' color={'green'} />*!/*/}

                {/*            /!*        <List.Content>*!/*/}
                {/*            /!*            <List.Header>Client collaboration</List.Header>*!/*/}
                {/*            /!*            <List.Description>*!/*/}
                {/*            /!*               Invite as many clients as you'd like to get feedback on site progress and tasks.*!/*/}
                {/*            /!*            </List.Description>*!/*/}
                {/*            /!*        </List.Content>*!/*/}
                {/*            /!*    </List.Item>*!/*/}
                {/*            /!*    <List.Item style={{paddingLeft:10}}>*!/*/}
                {/*            /!*        <Icon name='checkmark' color={'green'} />*!/*/}

                {/*            /!*        <List.Content>*!/*/}
                {/*            /!*            <List.Header>Stay on task</List.Header>*!/*/}
                {/*            /!*            <List.Description>*!/*/}
                {/*            /!*                Create and manage the site punchlist to keep you and your crews on task.*!/*/}
                {/*            /!*            </List.Description>*!/*/}
                {/*            /!*        </List.Content>*!/*/}
                {/*            /!*    </List.Item>*!/*/}
                {/*            /!*    <List.Item style={{paddingLeft:10}}>*!/*/}
                {/*            /!*        <Icon name='checkmark' color={'green'} />*!/*/}

                {/*            /!*        <List.Content>*!/*/}
                {/*            /!*            <List.Header>Geofencing to streamline Site access</List.Header>*!/*/}
                {/*            /!*            <List.Description>*!/*/}
                {/*            /!*                Geofencing technology will make it easy for your crews to access site information - without the hassle of sending invites or setting up accounts.*!/*/}
                {/*            /!*            </List.Description>*!/*/}
                {/*            /!*        </List.Content>*!/*/}
                {/*            /!*    </List.Item>*!/*/}
                {/*            /!*    <List.Item style={{paddingLeft:10}}>*!/*/}
                {/*            /!*        <Icon name='checkmark' color={'green'} />*!/*/}

                {/*            /!*        <List.Content>*!/*/}
                {/*            /!*            <List.Header>Unlimited users</List.Header>*!/*/}
                {/*            /!*            <List.Description>*!/*/}
                {/*            /!*                There are no limits to the number of users accessing your site.*!/*/}
                {/*            /!*            </List.Description>*!/*/}
                {/*            /!*        </List.Content>*!/*/}
                {/*            /!*    </List.Item>*!/*/}
                {/*            /!*    <List.Item style={{paddingLeft:10}}>*!/*/}
                {/*            /!*        <Icon name='checkmark' color={'green'} />*!/*/}

                {/*            /!*        <List.Content>*!/*/}
                {/*            /!*            <List.Header>No contracts or commitments</List.Header>*!/*/}
                {/*            /!*            <List.Description>*!/*/}
                {/*            /!*                Simply download the app and you're ready to go. You'll only get billed when you create a Site.*!/*/}
                {/*            /!*            </List.Description>*!/*/}
                {/*            /!*        </List.Content>*!/*/}
                {/*            /!*    </List.Item>*!/*/}
                {/*            /!*</List>*!/*/}

                {/*        </Grid.Column>*/}

                {/*    </Grid.Row>*/}
                {/*</Grid>*/}
            </Segment>

            <Segment style={{ backgroundColor:'#fff', padding: '4em 4em' }} vertical>
                <Header style={{textAlign:'center',alignSelf:'center',marginBottom:'2em'}} as={'h1'} content={`Tutorial Videos`} subheader={'Reach out to us with any questions at contact@skilledonsite.com.'}/>
                <Grid relaxed columns={4}>
                    <Grid.Column>
                        <Embed
                            style={{borderRadius:10}}
                            id='zKGUPmE77DQ'
                            // placeholder='/images/image-16by9.png'
                            source='youtube'
                            iframe={{
                                allowFullScreen: true,
                            }}
                            // active
                        />
                        <Header content={'Creating a Site'} style={{textAlign:'center',margin:'auto',marginTop:'1em'}} />

                    </Grid.Column>
                    <Grid.Column>
                        <Embed
                            style={{borderRadius:10}}
                            id='xMfozY0ycnI'
                            // placeholder='/images/image-16by9.png'
                            source='youtube'
                            iframe={{
                                allowFullScreen: true,
                            }}
                            // active
                        />
                        <Header content={'Creating a Crew'} style={{textAlign:'center',margin:'auto',marginTop:'1em'}} />

                    </Grid.Column>
                    <Grid.Column>
                        <Embed
                            style={{borderRadius:10}}
                            id='F-sLfPN8yfI'
                            // placeholder='/images/image-16by9.png'
                            source='youtube'
                            iframe={{
                                allowFullScreen: true,
                            }}
                            // active
                        />
                        <Header content={'Creating a Punchlist'} style={{textAlign:'center',margin:'auto',marginTop:'1em'}} />
                        {/*<iframe width="560" height="315" src="https://www.youtube.com/embed/F-sLfPN8yfI" frameBorder="0"*/}
                        {/*        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"*/}
                        {/*        allowFullScreen></iframe>*/}
                    </Grid.Column>
                    <Grid.Column>
                        <Embed
                            style={{borderRadius:10}}
                            id='5-4-YLNh60Y'
                            // placeholder='/images/image-16by9.png'
                            source='youtube'
                            iframe={{
                                allowFullScreen: true,
                            }}
                            // active
                        />
                        <Header content={'Messaging'} style={{textAlign:'center',margin:'auto',marginTop:'1em'}} />

                    </Grid.Column>
                </Grid>
                <Header style={{textAlign:'center',marginTop:'2em'}} as={'h1'} content={'Still have questions?'} subheader={'Email us at contact@skilledonsite.com'} />
                <div style={{justifyContent:'center',textAlign:'center'}}>
                    <Button
                        style={{margin:'auto'}}
                        as={'a'}
                        color={'blue'}
                        content={'Email Us!'}
                        size={'large'}
                        onClick={()=>window.location='mailto:contact@skilledonsite.com'}
                    />
                </div>
            </Segment>

        </div>
    )
  }
}
