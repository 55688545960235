import React, {Component} from 'react'
import {StripeProvider} from 'react-stripe-elements';
import {Elements} from 'react-stripe-elements';
import CheckoutForm from './components/checkoutForm'
import StripeService from "../../services/stripe";
export default class StripeCustomerUpdate extends Component {

  constructor(props) {
      super(props)


      this.state = {
          stripePk: null
      }


  }


  getStripePk() {
        return StripeService.getPk()
            .then((res)=>{
                console.log(res,"KEU-")
                this.setState({
                    stripePk:res.key
                })
            })
  }

  componentDidMount() {

      this.getStripePk()
  }

    render(){

        return (
            this.state.stripePk ?
                <StripeProvider apiKey={this.state.stripePk}>
                    <Elements>
                        <CheckoutForm processInvoice={this.props.processInvoice} hideCancelToggle={this.props.hideCancelToggle} updateParentState={this.props.updateParentState} getCustomers={this.props.getCustomers} customer={this.props.customer} updateParentFeed={this.props.updateParentFeed}/>
                    </Elements>
                </StripeProvider>
            :
                null
        )
    }
}
