import React, {Component} from 'react'
import {isAndroid, isIOS} from "react-device-detect";
import UtilitiesService from "../../services/utilities";



export default class DownloadRedirect extends Component {


  constructor(props) {
      super(props)

      // getLocalStorageJWT
      // setLocalStorageJWT
      // jwt
      // getUserObject
      // userObject : { user }

      this.state = {
          userData:null,
          mobile:UtilitiesService.mobileCheck()
      }

  }



  render(){

      let mobile = this.state.mobile
      if (mobile) {
          //take user to download app in app store
          if (isAndroid) {
              window.location = `https://play.google.com/store/apps/details?id=com.skilledonsite.app`
          }

          if (isIOS) {
              window.location = `https://apps.apple.com/us/app/skilled-on-site/id1472754541`
          }
      } else {
          window.location = 'https://skilledonsite.com'
      }
    return (
        <div>
        </div>
    )
  }
}