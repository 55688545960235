import React, {Component} from 'react'
import { Menu, Button,Popup, Container, Segment, Card, Header, Icon, Image, Grid, List, Item, Divider, Modal, Loader, Dimmer, Form, Message} from 'semantic-ui-react'
import io from 'socket.io-client'
import Networking from '../../services/networking'


const socketBase = Networking.socketSetup()

let connected = false
let authed = false
let disconnected = true
let channelUUID = null
let joined = false
let inError = false
let cb = null

export default class SocketComponent extends Component {

  constructor(props) {
      super(props)

      this.state = {
          connected: false,
          authed: false,
          joinedPost:false,
          postUUID:props.postUUID,
          disconnected: true,
          channelUUID: props.channelUUID,
          jobUUID:props.jobUUID,
          joinedJob: false,
          joinedChannel:false,
          inError: false,
          socket: io(socketBase, {transports: ['websocket']}),

          //handleSocketCB:props.handleSocketCB()
      }

  }

  joinChannel(uuid) {

      this.state.channelUUID && this.state.socket && this.state.socket.emit('channel:leave',{uuid:this.state.channelUUID}, (res)=>{
          this.setState({
              joinedChannel:false,
              channelUUID:null
          })
      })

      this.state.socket.emit('channel:join',{uuid:uuid}, (res) => {

          if (!this.state.connected || !this.state.authed) {
              this.setState({
                  channelUUID:uuid,
                  joinedChannel:false
              })
              return
          }

          if (this.state.inError) {
              // this.props.handleSocketCB({showError:false,message:`Connected to server.`})
              this.setState({
                  channelUUID:uuid,
                  inError:false
              })

              return
          }

          this.setState({
              channelUUID:uuid,
              joinedChannel:true
          })


          console.log("JOINED Channel---")
          this.props.handleSocketCB({state:'connectionStatus',showError:false,message:`Connected to server.`})
      })
  }


    leaveChannel(uuid) {


        this.state.socket.emit('channel:leave',{uuid:uuid}, (res) => {

            this.setState({
                channelUUID:null,
                joinedChannel:false
            })


        })
    }

  joinPost(uuid) {
      this.state.postUUID && this.state.socket && this.state.socket.emit('post:leave',{uuid:this.state.postUUID}, (res)=>{
          this.setState({
              joinedPost:false,
              postUUID:null
          })
      })


      this.state.socket.emit('post:join',{uuid:uuid}, (res) => {

          if (!this.state.connected || !this.state.authed) {
              this.setState({
                  postUUID:uuid,
                  joinedPost:false
              })
              return
          }

          if (this.state.inError) {
              // EventEmitter.emit('TrivPlayer:connectionStatus',{showError:false,message:`Connected to server.`})
              // this.props.handleSocketCB({showError:false,message:`Connected to server.`})
              this.setState({
                  postUUID:uuid,
                  inError:false
              })

              return
          }

          this.setState({
              postUUID:uuid,
              joinedPost:true
          })


          console.log("JOINED Post---")
          this.props.handleSocketCB({state:'connectionStatus',showError:false,message:`Connected to server.`})
          // joined = true
      })
  }

    leavePost(uuid) {


        this.state.socket.emit('post:leave',{uuid:uuid}, (res) => {

            this.setState({
                postUUID:null,
                joinedPost:false
            })


        })
    }


    joinJob(uuid) {


        this.state.jobUUID && this.state.socket && this.state.socket.emit('job:leave',{uuid:this.state.jobUUID}, (res)=>{
            this.setState({
                joinedJob:false,
                jobUUID:null
            })
        })


        this.state.socket.emit('job:join',{uuid:uuid}, (res) => {

            if (!this.state.connected || !this.state.authed) {
                this.setState({
                    jobUUID:uuid,
                    joinedJob:false
                })
                return
            }

            if (this.state.inError) {
                // EventEmitter.emit('TrivPlayer:connectionStatus',{showError:false,message:`Connected to server.`})
                // this.props.handleSocketCB({showError:false,message:`Connected to server.`})
                this.setState({
                    jobUUID:uuid,
                    inError:false
                })

                return
            }

            this.setState({
                jobUUID:uuid,
                joinedJob:true
            })


            console.log("JOINED Job---")
            this.props.handleSocketCB({state:'connectionStatus',showError:false,message:`Connected to server.`})
            // joined = true
        })
    }

    leaveJob(uuid) {


        this.state.socket.emit('job:leave',{uuid:uuid}, (res) => {

            this.setState({
                jobUUID:null,
                joinedJob:false
            })


        })
    }


    componentWillUnmount() {
        this.state.channelUUID && this.leaveChannel(this.state.channelUUID)
        this.state.postUUID && this.leavePost(this.state.postUUID)
        this.state.jobUUID && this.leaveJob(this.state.jobUUID)

    }
  componentDidMount() {


      this.state.socket.on('authenticated', (res) => {
          this.setState({
              authed:true
          })


          if (this.state.channelUUID && !this.state.joinedChannel) {
              this.joinChannel(this.state.channelUUID)
          }

          if (this.state.jobUUID && !this.state.joinedJob) {
              this.joinJob(this.state.jobUUID)
          }

          if (this.state.postUUID && !this.props.joinedPost) {
              this.joinPost(this.state.postUUID)
          }

          // if (this.state.inError && !this.state.channelUUID) {
          //
          //     // EventEmitter.emit('TrivPlayer:connectionStatus',{showError:false,message:`Connected to server.`})
          //     // emitter.emit('socket:authenticated', 'Connected to server');
          //     this.props.handleSocketCB({state:'connectionStatus',showError:false,message:`Connected to server.`})
          //     this.setState({
          //         inError:false
          //     })
          //
          // }


          console.log("authenticated")
          //EventRegister.emit('Socket:authenticated',res)
      })

      this.state.socket.on('error', (res) => {
          console.log("ERROR",res)
          this.setState({
              connected : false,
              authed : false,
              joinedChannel : false,
              joinedJob : false,
              joinedPost : false,
              inError : true
          })
          // EventEmitter.emit('TrivPlayer:connectionStatus',{showError:true,message:`Lost connection to server.`})
          this.props.handleSocketCB({state:'connectionStatus',showError:true,message:`Lost connection to server.`})

          // EventEmitter.emit('Socket:error',res)
      })

      this.state.socket.on("reconnecting", (res) => {
          console.log("ERROR",res)
          this.setState({
              connected : false,
              authed : false,
              joinedChannel : false,
              joinedPost : false,
              joinedJob : false,
              inError : true
          })
          this.props.handleSocketCB({state:'connectionStatus',showError:true,message:`Attempting to reconnect...`})
          // console.log('on reconnecting');
          // EventEmitter.emit('TrivPlayer:connectionStatus',{showError:true,message:`Attempting to reconnect...`})

          // EventEmitter.emit('Socket:reconnecting',res)
      });

      this.state.socket.on("reconnect_error", (res) => {
          console.log("ERROR",res)
          this.setState({
              connected : false,
              authed : false,
              joinedChannel : false,
              joinedPost : false,
              joinedJob : false,
              inError : true
          })
          this.props.handleSocketCB({state:'connectionStatus',showError:true,message:`Lost connection to server.`})
          // console.log('on reconnect_error');
          // EventEmitter.emit('TrivPlayer:connectionStatus',{showError:true,message:`Lost connection to server.`})
          // EventEmitter.emit('Socket:reconnect_error',res)

      });

      this.state.socket.on("reconnect_failed", (res) => {
          this.setState({
              connected : false,
              authed : false,
              joinedPost : false,
              joinedChannel : false,
              joinedJob : false,
              inError : true
          })
          this.props.handleSocketCB({state:'connectionStatus',showError:true,message:`Lost connection to server.`})
          // console.log('on reconnect_failed');
          // EventEmitter.emit('TrivPlayer:connectionStatus',{showError:true,message:`Lost connection to server.`})
          // EventEmitter.emit('Socket:reconnect_failed',res)

      });

      this.state.socket.on('disconnect', (res) => {
          this.setState({
              connected : false,
              authed : false,
              joinedPost : false,
              joinedChannel : false,
              joinedJob : false,
              inError : true
          })

          // connected = false
          // authed = false
          // joined = false
          // inError = true
          this.props.handleSocketCB({state:'connectionStatus',showError:true,message:`Lost connection to server.`})
          // console.log('user disconnected');
          // EventEmitter.emit('TrivPlayer:connectionStatus',{showError:true,message:`Lost connection to server.`})
          // EventEmitter.emit('Socket:disconnect',res)

          // io.emit('user disconnected');
      });

      // this.this.state.socket.on("message", (msg) => {
      //     this.socketObserver.next({ category: 'message', message: msg });
      // }); //end of this.state.socket.on('message')


      /** Real-Time events **/
      this.state.socket.on("channel:new:post", (res) => {
          console.log(res)
          console.log("new Channel Post -----")
          this.props.handleSocketCB({state:'newPost',data:res})

          // EventRegister.emit('Socket:channel:new:post',res)
      })

      this.state.socket.on("channel:post:update", (res) => {
          console.log(res)
          console.log("new Channel Update -----")
          this.props.handleSocketCB({state:'postUpdate',data:res})

          // EventRegister.emit('Socket:channel:post:update',res)
      })

      this.state.socket.on("post:reply", (res) => {
          console.log(res)
          console.log("new Post Reply -----")
          this.props.handleSocketCB({state:'postReply',data:res})

          // EventRegister.emit('Socket:post:reply',res)
      })

      this.state.socket.on("job:update:members", (res) => {
          console.log(res)
          console.log("new Job Members -----")
          this.props.handleSocketCB({state:'updateMembers',data:res})

          // EventRegister.emit('Socket:job:update:members',res)
      })

      this.state.socket.on("job:update:clientadmins", (res) => {
          console.log(res)
          console.log("new Job Clientadmins -----")
          this.props.handleSocketCB({state:'updateClientAdmins',data:res})

          // EventRegister.emit('Socket:job:update:clientadmins',res)
      })

      this.state.socket.on("job:update:admins", (res) => {
          console.log(res)
          console.log("new Job Admins -----")
          // EventRegister.emit('Socket:job:update:admins',res)
          this.props.handleSocketCB({state:'updateAdmins',data:res})

      })

      this.state.socket.on("job:update:channels", (res) => {
          console.log(res)
          console.log("new Job Channels -----")
          // EventRegister.emit('Socket:job:update:channels',res)
          this.props.handleSocketCB({state:'updateChannels',data:res})

      })

      this.state.socket.on("job:update:punchlist", (res) => {
          console.log(res)
          console.log("new Job Punchlist Update -----")
          // EventRegister.emit('Socket:job:update:punchlist',res)
          this.props.handleSocketCB({state:'updatePunchlist',data:res})

      })

      this.state.socket.on('connect', (res) => {
          console.log("connected")
          this.setState({connected:true})
          if (this.state.inError) {
              console.log('error')

              // EventEmitter.emit('TrivPlayer:connectionStatus',{showError:true,message:`Attempting to reconnect...`})
              this.props.handleSocketCB({state:'connectionStatus',showError:true,message:`Attempting to reconnect...`})
          }
          let jwt = null
          // console.log(localStorage.getItem('jwt_token'),"JWT STUFF-=")
          if (localStorage) {
              jwt = localStorage.getItem('jwt_token')
          }
          // console.log(jwt)
          this.state.socket.emit('authenticate', {token:jwt})
          if (!jwt) {
              console.log("ERRORIN")
              this.setState({
                  inError:true
              })
          }


      })


  }

  render() {
      return null
  }

}
