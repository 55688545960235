import React, {Component} from 'react'
import {NavLink, Link} from 'react-router-dom'
import { Menu, Button, Container, Image,Label,Grid,Icon,Advertisement,Header,Message,Segment} from 'semantic-ui-react'
import UserService from "../../services/user";
import logo600 from '../../../img/skilled_logo_600x340.jpg'
import UtilitiesService from "../../services/utilities";

export default class NavBar extends Component {

    constructor(props){
        super(props)

        this.state = {
            userData:null,
            mobile:UtilitiesService.mobileCheck(),
            offset:0
        }
    }
    componentDidMount(){
        // if (this.props.userObject && this.state.userData) return
        // this.props.getLocalStorageJWT()
        // this.props.getUserObject()


        // console.log("runing this somewhere")
        return UserService.getUser(true)
            .then((user) => {
                this.setState({
                    userData:user
                })



            })
            .catch((err)=>{

                // UserService.logout()
                // window.location = `/`
            })

    }


  render(){
        let mobile = UtilitiesService.mobileCheck()
    return (
        <Segment centered basic>
            <Menu style={ {borderRadius:0,backgroundColor:'#fff'}} secondary borderless fluid stackable color={"#041626"} size='large'>
                <Container>
                    <Menu.Item>
                        <Image as={NavLink} exact href={'/'} to="/" src={logo600} size="small"/>
                    </Menu.Item>




                    {
                        !this.props.minimal && this.state.userData ?
                            <Menu.Item as={NavLink} exact to="/profile">
                                Profile
                            </Menu.Item>
                            :
                            null
                    }

                    {
                        !this.props.minimal && !this.state.userData ?
                            <Menu.Item content="Login" as={NavLink} exact to="/login"/>
                            :
                            null
                    }

                    {
                        !this.props.minimal && !this.state.userData ?
                            <Menu.Item content="Register" as={NavLink} exact to="/register"/>
                            :
                            null
                    }

                    {/*{*/}
                    {/*    !this.props.minimal && this.state.userData ?*/}
                    {/*        <Menu.Item content="Find Work" as={NavLink} exact to="/jobs"/>*/}
                    {/*    :*/}
                    {/*        null*/}
                    {/*}*/}


                    {/*{*/}
                    {/*    !this.props.minimal && this.state.userData ?*/}
                    {/*        <Menu.Item content="Hire Labor" as={NavLink} exact to="/hire"/>*/}
                    {/*        :*/}
                    {/*        null*/}
                    {/*}*/}


                </Container>
            </Menu>
        </Segment>
    )
  }
}
