/**
 * Created by salprincipato on 5/31/17.
 */
import React from 'react';

import Networking from './networking'



export default class PunchlistService {

    // static endpointBase = Networking.networkSetup('production')

    constructor() {
        this.state = {
            endpointBase:Networking.networkSetup('production')
        }
    }




    /** Create a Punchlist **/
    static async create(data) {

        return await fetch(`${await Networking.networkSetup(this.endpointBase)}/v1/punchlist`,{
            method: "POST",
            headers: await Networking.getHeader(),
            body: JSON.stringify(data)
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }


    /** update Punchlist  **/
    static async update(data) {

        return await fetch(`${await Networking.networkSetup(this.endpointBase)}/v1/punchlist`,{
            method: "PUT",
            headers: await Networking.getHeader(),
            body: JSON.stringify(data)
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** get Punchlist by channel  **/
    static async getByChannel(channelUUID,limit,offset) {

        return await fetch(`${await Networking.networkSetup(this.endpointBase)}/v1/punchlist?channelUUID=${channelUUID}&limit=${limit}&offset=${offset}`,{
            method: "GET",
            headers: await Networking.getHeader()
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** add Channel to Punchlist **/
    static async addChannel(punchlistUUID,channelUUID) {

        return await fetch(`${await Networking.networkSetup(this.endpointBase)}/v1/punchlist/add/channel?puuid=${punchlistUUID}&cuuid=${channelUUID}`,{
            method: "PUT",
            headers: await Networking.getHeader()
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** remove Channel to Punchlist **/
    static async removeChannel(punchlistUUID,channelUUID) {

        return await fetch(`${await Networking.networkSetup(this.endpointBase)}/v1/punchlist/remove/channel?puuid=${punchlistUUID}&cuuid=${channelUUID}`,{
            method: "PUT",
            headers: await Networking.getHeader()
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** get Punchlist by Job  **/
    static async getByJob(jobUUID,limit,offset) {

        return await fetch(`${await Networking.networkSetup(this.endpointBase)}/v1/punchlist?jobUUID=${jobUUID}&limit=${limit}&offset=${offset}`,{
            method: "GET",
            headers: await Networking.getHeader()
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** add Job to Punchlist **/
    static async addJob(punchlistUUID,jobUUID) {

        return await fetch(`${await Networking.networkSetup(this.endpointBase)}/v1/punchlist/add/job?puuid=${punchlistUUID}&juuid=${jobUUID}`,{
            method: "PUT",
            headers: await Networking.getHeader()
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** remove Job to Punchlist **/
    static async removeJob(punchlistUUID,jobUUID) {

        return await fetch(`${await Networking.networkSetup(this.endpointBase)}/v1/punchlist/remove/job?puuid=${punchlistUUID}&juuid=${jobUUID}`,{
            method: "PUT",
            headers: await Networking.getHeader()
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }



    /** add Punchlistitem to Task **/
    static async addPunchlistTask(punchlistUUID,taskUUID) {

        return await fetch(`${await Networking.networkSetup(this.endpointBase)}/v1/punchlist/add/task?puuid=${punchlistUUID}&tuuid=${taskUUID}`,{
            method: "PUT",
            headers: await Networking.getHeader()
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** remove Punchlistitem to Punchlist **/
    static async removePunchlistTask(punchlistUUID,taskUUID) {

        return await fetch(`${await Networking.networkSetup(this.endpointBase)}/v1/punchlist/remove/task?puuid=${punchlistUUID}&tuuid=${taskUUID}`,{
            method: "PUT",
            headers: await Networking.getHeader()
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }


}