
// import React from 'react';

// let jwt = null


export default class Networking {

    static env = process.env.NODE_ENV
    static devApiEndpoint = process.env.DEV_API_ENDPOINT
    static devRealtimeEndpoint = process.env.DEV_REALTIME_ENDPOINT
    static configuredSocketEndpoint = (()=>{
        let development = this.devRealtimeEndpoint || 'http://localhost:8081'
        let staging = 'https://realtime.staging.skilledonsite.com'
        let production = 'https://realtime.skilledonsite.com'


        let sockEp =(this.env === 'production') ? production
          : (this.env === 'staging') ? staging
            : development

        console.log(`Socket endpoint is ${sockEp}`)
        return sockEp
    })()

    static configuredRestEndpoint = (()=>{
        let development = this.devApiEndpoint || 'http://localhost:8080'
        let staging = 'https://api.staging.skilledonsite.com'
        let production = 'https://api.skilledonsite.com'


        let restEp = (this.env === 'production') ? production
          : (this.env === 'staging') ? staging
            : development

        console.log(`REST endpoint is ${restEp}`)
        return restEp
    })()

    static socketSetup(){
        return this.configuredSocketEndpoint
        // let development = this.devRealtimeEndpoint || 'http://localhost:8081'
        // let staging = 'https://realtime.staging.skilledonsite.com'
        // let production = 'https://realtime.skilledonsite.com'
        //
        //
        // return (this.env === 'production') ? production
        //   : (this.env === 'staging') ? staging
        //     : development
    }

    static networkSetup(setting, type) {
        return this.configuredRestEndpoint
        // let development = this.devApiEndpoint || 'http://localhost:8080'
        // let staging = 'https://api.staging.skilledonsite.com'
        // let production = 'https://api.skilledonsite.com'
        //
        //
        // return (this.env === 'production') ? production
        //   : (this.env === 'staging') ? staging
        //     : development

    }

    static  getHeader() {
        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }

        let jwt = false
        if (localStorage.getItem('jwt_token')) {
            jwt = localStorage.getItem('jwt_token')
        }
        // console.log(localStorage.getItem('jwt_token'),"TOKEN FROM GET HEADER-=-=--")


        if (jwt) {

            headers = {
                'Accept': 'application/json',
                'Content-Type':  'application/json',
                'Authorization': 'Bearer ' + jwt,
            }

        }

        return headers
    }

}