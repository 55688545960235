/**
 * Created by salprincipato on 5/31/17.
 */
import React from 'react';

import Networking from './networking'

export default class ImageService {


    constructor() { }



    static getAvatarImage(size,imgArr) {


        let img = "https://s3.us-east-2.amazonaws.com/images.skilledonsite.com/static/skilledguy-small.png"
        if (imgArr && imgArr.length && imgArr[0].url) {
            img = imgArr[0].url
        }
        return img


        let linkUrl;
        let avatarAvailable = !(!imgArr || !imgArr.length)

        if (imgArr && imgArr.length && avatarAvailable === true && size === 'small') {
            // linkUrl = _.find(imgArr, (a) => {
            //   console.log(a)
            //   return a.width === "50"
            // })

            for (let i = 0; imgArr.length > i; i++) {
                if (imgArr[i].width === "50") {
                    linkUrl = imgArr[i].path
                }

            }

        }
        if (imgArr && imgArr.length && avatarAvailable === true && size === 'medium') {
            // linkUrl = _.find(imgArr, (a) => {
            //   return a.width === "150"
            // })

            for (let i = 0; imgArr.length > i; i++) {
                if (imgArr[i].width === "150") {
                    linkUrl = imgArr[i].path
                }

            }
        }
        if (imgArr && imgArr.length && avatarAvailable === true && size === 'large') {
            // linkUrl = _.find(imgArr, (a) => {
            //   return a.width === "500"
            // })

            for (let i = 0; imgArr.length > i; i++) {
                if (imgArr[i].width === "500") {
                    linkUrl = imgArr[i].path
                }

            }
        }

        if (avatarAvailable === false) {
            return require("../../img/skilledguy-small.png")
        }
        return "https://s3.amazonaws.com/avatars.skilledonsite.com"+linkUrl;


    }


}