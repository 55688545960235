import React from 'react'
import { Menu, Button, Container, Segment, Header, Icon, Image, Grid, List, Item, Divider} from 'semantic-ui-react'
import {NavLink, Link} from 'react-router-dom'
import UtilitiesService from "../../services/utilities";

import googlePlay from '../../../img/google-play-badge.png'
import appStore from '../../../img/app-store-badge.svg'

export class FooterComponent extends React.Component {

  constructor(props) {
      super(props)

      this.state = {
          mobile:UtilitiesService.mobileCheck()
      }
  }

  render(){

    return (
        <Segment inverted vertical style={this.state.mobile ? {borderRadius:0,backgroundColor:'#1b1c1d',marginBottom:0,padding: '5em 0em'} : {borderRadius:0,backgroundColor:'#1b1c1d',padding: '5em 0em'} }>
            <Container>
                <Grid divided inverted stackable>
                    <Grid.Row>
                        <Grid.Column width={3}>
                            <Header inverted as='h4' content='Links' />
                            <List link inverted>
                                <List.Item as='a' href="/" target="_blank">Home</List.Item>
                                {/*<List.Item as='a' href="/pricing" target="_blank">Pricing</List.Item>*/}
                                {/*<List.Item as='a' href="/help" target="_blank">Help</List.Item>*/}
                                <List.Item as='a' href="mailto:contact@skilledonsite.com?subject=I%20have%20something%20to%20tell%20you">Email Us</List.Item>

                            </List>
                        </Grid.Column>

                        <Grid.Column width={3}>
                            <Header as='h4' inverted content={'Skilled on Site'}/>
                            <List link inverted>
                                <List.Item as='a' href="/legal?legalFile=tos" target="_blank">Terms of Service</List.Item>
                                <List.Item as='a' href="/legal?legalFile=pp" target="_blank">Privacy Policy</List.Item>
                                <List.Item as='a' href="/legal?legalFile=eula" target="_blank">End User License Agreement</List.Item>
                            </List>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        </Segment>
    )
  }
}
