import React, {Component} from 'react'
import {StripeProvider} from 'react-stripe-elements';
import {Elements} from 'react-stripe-elements';
import CheckoutForm from './components/checkoutForm'
import StripeService from "../../services/stripe";
export default class StripeCheckoutPurchaseContainer extends Component {

  constructor(props) {
      super(props)


      this.state = {
          stripePk: null,

      }


  }


  getStripePk() {
        return StripeService.getPk()
            .then((res)=>{

                this.setState({
                    stripePk:res.key
                })
            })
  }

    componentDidMount() {

      this.getStripePk()
  }

    render (){

        return (
            this.state.stripePk ?
                <StripeProvider apiKey={this.state.stripePk}>
                    <Elements>
                        <CheckoutForm
                            updateActiveIndex={this.props.updateActiveIndex}
                            getCustomers={this.props.getCustomers}
                            makePurchase={this.props.makePurchase}
                            hideCancelToggle={this.props.hideCancelToggle}
                            updateParentState={this.props.updateParentState}
                            userData={this.props.userData}
                            customer={this.props.customer}
                        />
                    </Elements>
                </StripeProvider>
            :
                null
        )
    }
}
