/**
 * Created by salprincipato on 5/31/17.
 */
import React from 'react';

import Networking from './networking'


const endpointBase = Networking.networkSetup('production')

export default class UploadService {

    // static endpointBase = Networking.networkSetup('production')

    constructor() {
        // this.state = {
        //     endpointBase:Networking.networkSetup('production')
        // }
    }



    static async uploadFiles(files, processTask){
      let currentUpload = await fetch(`${endpointBase}/v1/upload`, {method: 'post', headers:Networking.getHeader()})
      if (!currentUpload.ok) {
        throw new Error(`${currentUpload.status}: ${currentUpload.statusText}`);
      }
      let currentUploadJson = await currentUpload.json()
      let uploadMethods = currentUploadJson.methods

      // Create our array of s3 uploads
      let pendingUploads = files.map(async (file) => {
        let addfileConf = {
          method: uploadMethods.addFile.method,
          headers: Networking.getHeader(),
          body: JSON.stringify({
            contentType: file.type,
            processTask: processTask
          })
        }

        let addedFile = await fetch(uploadMethods.addFile.url, addfileConf)
        if (!addedFile.ok) {
          return {ok: false, error: new Error(`${completeFile.status}: ${completeFile.statusText}`)}
        }
        let addedFileJson = await addedFile.json()
        let fileMethods = addedFileJson.methods

        let s3UploadConf = {
          method: fileMethods.upload.method,
          body: file
        }
        let s3Response = await fetch(fileMethods.upload.url, s3UploadConf)
        if (!s3Response.ok) {
          return {ok: false, error: new Error(`${completeFile.status}: ${completeFile.statusText}`)}
        }
        let completeFileConf = {
          method: fileMethods.completeFile.method,
          headers: Networking.getHeader()
        }
        let completeFile = await fetch(fileMethods.completeFile.url, completeFileConf)
        if (!completeFile.ok) {
          return {ok: false, error: new Error(`${completeFile.status}: ${completeFile.statusText}`)}
        }

        let completeFileJson = await completeFile.json()
        return {ok: true, error: null}
      })

      let uploadResults = await Promise.all(pendingUploads)
      let completeUploadConf = {
        method: uploadMethods.completeUpload.method,
        headers: Networking.getHeader()
      }

      let completeUpload = await fetch(uploadMethods.completeUpload.url, completeUploadConf)
      let completeUploadJson = await completeUpload.json()

      return completeUploadJson
    }


    /** Use to create an upload **/
    static create() {

        return fetch(`${endpointBase}/v1/upload`,{
            method: "POST",
            headers: Networking.getHeader(),
            // body: JSON.stringify({nothing:null})
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }


    /** Use to create an upload **/
    static getStatus(uuid) {

        return fetch(`${endpointBase}/v1/upload/${uuid}/status`,{
            method: "GET",
            headers: Networking.getHeader(),
            // body: JSON.stringify({nothing:null})
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

  static async uploadFilesNew(files, processTask){
    let currentUpload = await fetch(`${await Networking.networkSetup(this.endpointBase)}/v1/upload`, {method: 'post'})
    if (!currentUpload.ok) {
      throw new Error(`${currentUpload.status}: ${currentUpload.statusText}`);
    }
    let currentUploadJson = await currentUpload.json()
    let uploadMethods = currentUploadJson.methods

    // Create our array of s3 uploads
    let pendingUploads = files.map(async (file) => {
      console.log(file,"FIL IN LOOP-=")
      let addfileConf = {
        method: uploadMethods.addFile.method,
        headers: {
          'Accept': 'application/json',
          'Content-Type':  'application/json',
          // 'Authorization': `Bearer ${jwt}`,
        },
        body: JSON.stringify({
          contentType: file.mime,
          processTask: processTask
        })
      }
      console.log(addfileConf,"addfileConf--")

      let addedFile = await fetch(uploadMethods.addFile.url, addfileConf)
      console.log(addedFile,"ADDED FILE")
      if (!addedFile.ok) {
        return {ok: false, error: new Error(`${addedFile.status}: ${addedFile.statusText}`)}
      }
      let addedFileJson = await addedFile.json()
      console.log(addedFileJson,"ADDED FILE JSON")

      let fileMethods = addedFileJson.methods

      let s3UploadConf = {
        method: fileMethods.upload.method,
        body: file
      }

      console.log(s3UploadConf,"s3UploadConf--")

      // let s3Response = await fetch(fileMethods.upload.url, s3UploadConf)
      let s3Response = asyncXML(file, fileMethods.upload.url)

      console.log(s3Response,"S3Response")
      // if (!s3Response.ok) {
      //   return {ok: false, error: new Error(`${s3Response.status}: ${s3Response.statusText}`)}
      // }
      let completeFileConf = {
        method: fileMethods.completeFile.method,
        headers: {
          'Accept': 'application/json',
          // 'Authorization': `Bearer ${jwt}`,
        }
      }
      let completeFile = await fetch(fileMethods.completeFile.url, completeFileConf)
      console.log(completeFile,"COMPLETE FILE")
      if (!completeFile.ok) {
        return {ok: false, error: new Error(`${completeFile.status}: ${completeFile.statusText}`)}
      }

      return {ok: true, error: null}
    })

    let uploadResults = await Promise.all(pendingUploads)
    // console.log(uploadResults,"UPLOAD RESULTS")
    let completeUploadConf = {
      method: uploadMethods.completeUpload.method,
      headers: {
        'Accept': 'application/json',
        // 'Authorization': `Bearer ${jwt}`,
      }
    }

    let completeUpload = await fetch(uploadMethods.completeUpload.url, completeUploadConf)
    let completeUploadJson = await completeUpload.json()

    return completeUploadJson
  }

}

async function asyncXML(file, signedUrl){
  return new Promise((resolve, reject) => {
    let xhr = new XMLHttpRequest()
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          console.log(xhr)
          resolve({ok: true})
        } else {
          console.log(xhr)
          // The file could not be uploaded.
          reject(new Error('Its broke'))
        }
      }
    }

    xhr.open('PUT', signedUrl)
    xhr.setRequestHeader('Content-Type', file.type)
    xhr.send({ uri: file.path, type: file.mime, name: file.filename })
  })
}

//https://skilled-image-staging.s3.us-east-2.amazonaws.com/b7f18123-46d7-4f51-a488-a1bcf27a7943?Content-Type=image%2Fpng&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIARHFB3EUGBSJ6UMN6%2F20190914%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20190914T160252Z&X-Amz-Expires=3600&X-Amz-Signature=6c748131cafbb79897b7f3a8b98c580a72b0df87f1caea127783d65ef1ee1ba5&X-Amz-SignedHeaders=host%3Bx-amz-meta-environment%3Bx-amz-meta-fileuuid%3Bx-amz-meta-owneruuid%3Bx-amz-meta-processtask%3Bx-amz-meta-uploaduuid&x-amz-meta-environment=development&x-amz-meta-fileuuid=b7f18123-46d7-4f51-a488-a1bcf27a7943&x-amz-meta-owneruuid=&x-amz-meta-processtask=images.user.upload&x-amz-meta-uploaduuid=602d86b3-45a5-4e6b-a968-e3f8c6dcab0a