/**
 * Created by salprincipato on 5/31/17.
 */
import React from 'react';

import Networking from './networking'
import moment from "moment";

export default class UtilitiesService {

    // static endpointBase = Networking.networkSetup('production')

    constructor() {

        this.state = {
            endpointBase:Networking.networkSetup('production')
        }
    }


    static inListAlready(job,list){
        let found = false

        if (!list || !list.length) return found

        list.forEach((item)=>{
            if (item.location.googlePlacesId === job.location.googlePlacesId) {
                found = true
            }
        })

        return found
    }

    static mobileCheck() {
        return /Android|webOS|iPhone|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(window.navigator.userAgent)
    }

    static numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    static getParameterByName(name, url) {
        // if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    static displayTimeString(item,attr) {
        // console.log(item[attr],"AYYT-=")
        let timeString = moment(item[attr]).format('MMMM Do')

        let finalTimeString = timeString

        let yesterdayTime = moment(item[attr]).add(1,'days').format('MMMM Do')
        finalTimeString = `${timeString} @ ${moment(item[attr]).format('h:mm a')}`

        if (timeString === moment().format('MMMM Do')) {
            finalTimeString = `Today @ ${moment(item[attr]).format('h:mm a')}`
            // game.timeString = timeString

        }

        if (yesterdayTime === moment().format('MMMM Do')) {
            finalTimeString = `Yesterday @ ${moment(item[attr]).format('h:mm a')}`
            // game.timeString = timeString
        }


        return finalTimeString
    }

}
