/**
 * Created by salprincipato on 5/31/17.
 */
import React from 'react';

import Networking from './networking'


const endpointBase = Networking.networkSetup('production')

export default class UserService {


    constructor() {

    }




    /** Use to generate a user with no creds **/
    static generateAccount() {

        return fetch(`${endpointBase}/v1/user`,{
            method: "POST",
            headers: Networking.getHeader(),
            // body: JSON.stringify({nothing:null})
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** authenticate user login with code resource = <email/phone> **/
    static async loginWithCode(resource,userUUID,confirmationHash) {

        return await fetch(`${endpointBase}/v1/user/authentication/login/code`,{
            method: "PUT",
            headers: await Networking.getHeader(),
            body: JSON.stringify({resource:resource,userUUID:userUUID,confirmationHash:confirmationHash})
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** update user password **/
    static async updatePassword(data) {

        return await fetch(`${endpointBase}/v1/user/password`,{
            method: "PUT",
            headers: await Networking.getHeader(),
            body: JSON.stringify(data)
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** forgot user password **/
    static async forgotPassword(data) {

        return await fetch(`${endpointBase}/v1/user/forgot/password`,{
            method: "PUT",
            headers: await Networking.getHeader(),
            body: JSON.stringify(data)
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** Update user data - user.accountflag.requiresPassword **/
    static async update(data) {

        return await fetch(`${endpointBase}/v1/user`,{
            method: "PUT",
            headers: await Networking.getHeader(),
            body: JSON.stringify(data)
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }


    /** request a code to login resource = <email/phone> **/
    static async requestLoginCode(resource,userUUID) {

        return await fetch(`${endpointBase}/v1/user/authentication/request/code`,{
            method: "PUT",
            headers: await Networking.getHeader(),
            body: JSON.stringify({resource:resource,userUUID:userUUID})
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** authenticate user login with password **/
    static loginWithPassword(password,userUUID) {

        return fetch(`${endpointBase}/v1/user/authentication/login/password`,{
            method: "PUT",
            headers: Networking.getHeader(),
            body: JSON.stringify({password:password,userUUID:userUUID})
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }


    /** get channels the user is currently <active = true/false> **/
    static async findUserChannels(active,limit,offset) {

        active = active.toString()
        return await fetch(`${endpointBase}/v1/user?findUserChannels=true&active=${active}&limit=${limit}&offset=${offset}`,{
            method: "GET",
            headers: await Networking.getHeader()
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** get jobs the user is currently active **/
    static async findActiveUserJobs(limit,offset) {

        return await fetch(`${endpointBase}/v1/user?findActiveUserJobs=true&limit=${limit}&offset=${offset}`,{
            method: "GET",
            headers: await Networking.getHeader()
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** get channels the user is currently joined for a job **/
    static async findUserJobChannels(uuid,limit,offset) {

        return await fetch(`${endpointBase}/v1/user?findUserJobChannels=true&jobUUID=${uuid}&limit=${limit}&offset=${offset}`,{
            method: "GET",
            headers: await Networking.getHeader()
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** search channels the user is currently joined for a job **/
    static async searchUserJobChannels(search,uuid,limit,offset) {

        return await fetch(`${endpointBase}/v1/user?search=${search}&findUserJobChannels=true&jobUUID=${uuid}&limit=${limit}&offset=${offset}`,{
            method: "GET",
            headers: await Networking.getHeader()
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }


    /** lookup user by username **/
    static lookupByUsername(data) {

        // console.log(data,"DATA--=-=")
        return fetch(`${endpointBase}/v1/user?username=${data.value}`,{
            method: "GET",
            headers: Networking.getHeader()
        })
            .then((res) => {
                // console.log(res,"RES_+_+")
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** lookup user by email **/
    static lookupByEmail(data) {

        // console.log(data,"DATA--=-=")
        return fetch(`${endpointBase}/v1/user?email=${data.value}`,{
            method: "GET",
            headers: Networking.getHeader()
        })
            .then((res) => {
                // console.log(res,"RES_+_+")
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** lookup user by phone **/
    static async lookupByPhone(data) {

        return await fetch(`${endpointBase}/v1/user?phone=${data.value}&callingCode=${data.callingCode}`,{
            method: "GET",
            headers: await Networking.getHeader()
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** Get user account if available **/
    static getFresh() {
        let self = this
        let resUser
        return fetch(`${endpointBase}/v1/user`,{
            method: "GET",
            headers: Networking.getHeader()
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res)
                }
                // console.log(res.json())
                return res.json()
            })
            .then((user) => {
                resUser = user
                localStorage.setItem('userData',JSON.stringify(user))
                return resUser
            })
            .catch((err) => {
                throw Error(err)

            })
    }


    static logout() {
        // console.log(window.location.pathname,"PATH")

        // Networking.clearJWT()
        localStorage.removeItem('userData')
        localStorage.removeItem('jwt_token')



    }


    static getUser(fresh) {

        if (fresh) {
            return this.getFresh()
        }


        let user = localStorage.getItem('userData')

        if (user) {
            return Promise.resolve(JSON.parse(user))
        }


        return this.getFresh()



    }

    /** Use to generate a turn a user with no creds into a user with creds resource = <email/phone>  **/
    static async registerAccount(data,resource) {

        // console.log("register account---")
        return await fetch(`${endpointBase}/v1/user/registration/${resource}`,{
            method: "POST",
            headers: await Networking.getHeader(),
            body: JSON.stringify(data)
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** Get Req User  **/
    static async getReqUser() {

        return await fetch(`${endpointBase}/v1/user/`,{
            method: "GET",
            headers: await Networking.getHeader(),
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }


    /** RESEND HASH resource = <email/phone> **/
    static async resendConfirmationHash(resource) {

        return await fetch(`${endpointBase}/v1/user/resend/confirmation/${resource}`,{
            method: "PUT",
            headers: await Networking.getHeader(),
            // body: JSON.stringify({nothing:null})
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** Confirm credentials submitted  **/
    static async confirmAccount(data,resource) {

        return await fetch(`${endpointBase}/v1/user/confirmation/${resource}`,{
            method: "POST",
            headers: await Networking.getHeader(),
            body: JSON.stringify(data)
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }


    /** Logout User **/
    static async logout() {

        return await fetch(`${endpointBase}/v1/user/logout`,{
            method: "DELETE",
            headers: await Networking.getHeader()
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }


    /** Send Sales Inquiry **/
    static async salesInquiry(data) {

        return await fetch(`${endpointBase}/v1/user/sales/inquiry`,{
            method: "PUT",
            headers: await Networking.getHeader(),
            body:JSON.stringify({contactData:data})
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }


    static getDisplayName(user) {
        if (user.firstname) {
            return `Call ${user.firstname} ${user.lastname}`
        }

        return `Call ${user.username}`
    }
}
