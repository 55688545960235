import React, {Component} from 'react'
import {isAndroid, isIOS} from "react-device-detect";
import UtilitiesService from "../../services/utilities";
import {Button, Header, Image, List} from "semantic-ui-react";
import {NavLink} from "react-router-dom";
import logo600 from "../../../img/skilled_logo_600x340.jpg";



export default class AppRedirect extends Component {


  constructor(props) {
      super(props)

      // getLocalStorageJWT
      // setLocalStorageJWT
      // jwt
      // getUserObject
      // userObject : { user }

      this.state = {
          userData:null,
          mobile:UtilitiesService.mobileCheck()
      }

  }


    getQueryStringValue (key) {
        return decodeURIComponent(this.props.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));

    }

  componentDidMount() {

      // let mobile = this.state.mobile
      //
      // if (mobile) {
      //     //take user to download app in app store
      //     window.location = `skilledonsiteapp://${this.props.match.params.path}?key=${this.getQueryStringValue('key')}`
      //
      // } else {
      //     window.location = 'https://skilledonsite.com'
      // }

  }


    render(){

    return (
        <div>
            <Image src={logo600} size="medium" centered style={{marginTop:50,marginBottom:50}}/>
            <Header as={'h2'} content={`You've been invited to a Site!`} style={{textAlign:'center',marginTop:25}}/>

            <div style={{textAlign:'center'}}>
                <List ordered size={'huge'} style={{alignSelf:'center'}}>
                    <List.Item>Open the Skilled On Site app.</List.Item>
                    <List.Item>Click "Open in app" below.</List.Item>
                </List>
                <Button
                    color={"blue"}
                    size={'large'}
                    onClick={()=>window.location = `skilledonsiteapp://${this.props.match.params.path}?key=${this.getQueryStringValue("key")}`}
                    content={'Open in app'}
                />
            </div>


        </div>
    )
  }

}
