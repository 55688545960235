// CheckoutForm.js
import React from 'react';
import { Menu, Button, Container, Input, Label, Segment, Card, Dropdown, Header, Icon, Image, Grid, List, Item, Divider, Modal, Loader, Dimmer, Form, Message} from 'semantic-ui-react'

import Stripe,{StripeProvider,injectStripe} from 'react-stripe-elements';
import CardSection from './cardSection';
import StripeService from "../../../services/stripe";
import moment from "moment/moment";

class CheckoutForm extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            cardHolderName:props.customer && props.customer.name || props.userData && props.userData.fullName || '',
            cardHolderEmail:props.customer && props.customer.email || props.userData && props.userData.email || '',
            cardHolderPhone:props.customer && props.customer.phoneNumber || props.userData && props.userData.phoneNumber || '',
            loading:false,
            formSuccess:false,
            loadingText:'Processing Payment...',
            formError:null,
            updateComplete:false,
            newPaymentMethod:props.customer ? false : true,
            promoCode:null,
            subscriptionStartDate:moment().toDate(),
            startDateOptions:[],
            shareCode:null,
            showCancelAlert:false,
            // founder:props.venue.founder,
            // updateParentState:props.updateParentState()
            //venues:props.venues
            //cancelSubscription:this.props.cancelSubscription(),
            // customer: props.customer
            // venue:props.venue
            // updateParentFeed:updateParentFeed()
        }
    }


    componentDidMount() {


    }

    updatePaymentMethod(source) {

        return StripeService.update(this.props.customer.uuid,{
            source:source.id,
            name: this.state.cardHolderName,
            email: this.state.cardHolderEmail,
            phoneNumber: this.state.cardHolderPhone
        })
            .then((res)=>{
                if (res.err) {
                    this.setState({
                        loading:false,
                        formError:res.status || 'An error occurred processing the card. Please try again.'
                    })
                    return
                }

                this.setState({
                    formSuccess:true,
                    updateComplete:true
                },()=>{
                    this.props.updateParentState && this.props.updateParentState({updatePaymentModal:false,customerToUpdate:null})
                    //update parent lists of data

                    //This runs with just an update
                    this.props.getCustomers && this.props.getCustomers()


                })
            })
            .catch((err) => {
                // err = JSON.stringify(err)
                // console.log(err)
                setTimeout(()=>{
                    this.setState({ loading:false, formError:err.status || 'Please ensure you entered a valid card and try again.' })
                },2000)
            })
    }

    handleSubmit = (ev) => {

        this.setState({
            loading:true,
            loadingText:'Updating Payment Method...'
        })

        // We don't want to let default form submission happen here, which would refresh the page.
        ev.preventDefault();


        //UPDATE
        if (!this.state.cardHolderName.length || !this.state.cardHolderEmail.length || !this.state.subscriptionStartDate || !this.state.cardHolderPhone.length) {

            this.setState({ loading:false, formError:'Please complete the form to proceed.' })


            return
        }


        // Within the context of `Elements`, this call to createToken knows which Element to
        // tokenize, since there's only one in this group.
        this.props.stripe.createSource({
            type: 'card',
            owner: {
                name: this.state.cardHolderName,
                email:this.state.cardHolderEmail,
                phone:this.state.cardHolderPhone
            },
        })
            .then(({source}) => {

                this.updatePaymentMethod(source)

            }).catch((err)=>{
                // err = JSON.stringify(err)
                // console.log(err,"ERROR")
                setTimeout(()=>{
                    this.setState({ loading:false, formError:err.status || 'An error occurred. Please try again.' })
                },2000)
            })

        // However, this line of code will do the same thing:
        // this.props.stripe.createToken({type: 'card', name: 'Jenny Rosen'});
    }


    render() {
        let customer = this.props.customer

        return (

            <Form error={this.state.formError} success={this.state.formSuccess} loading={this.state.loading} onSubmit={this.handleSubmit}>
                {
                    this.state.formError ?
                        <Message
                            error
                            header='Error'
                            content={this.state.formError}
                        />
                    :
                        null
                }
                <Header as={'h4'} content={'Card Holder Information'}/>

                {
                    customer ?
                        <div>
                            <Form.Input fluid label='Full Name' type={'text'} value={this.state.cardHolderName} placeholder='e.g. John Doe' onChange={(event)=>{
                                this.setState({cardHolderName:event.target.value})
                            }} />
                            <Form.Input fluid label='Email' type={'email'} value={this.state.cardHolderEmail} placeholder='e.g. John@email.com' onChange={(event)=>{
                                this.setState({cardHolderEmail:event.target.value})
                            }} />

                            <Form.Input fluid label='Phone' type={'tel'} value={this.state.cardHolderPhone} placeholder='e.g. 555-555-5555' onChange={(event)=>{
                                this.setState({cardHolderPhone:event.target.value})
                            }} />
                        </div>
                    :
                        null
                }


                <Header as={'h4'} content={'Card Information'}/>

                {
                    customer ?
                        <div>
                            <CardSection/>
                            <br/>
                            <br/>

                            <div>
                                <p>Click "Update Card" below to save any changes made to your payment method.</p>
                                <Button primary>
                                    Update Card
                                </Button>
                            </div>

                        </div>
                    :
                        null
                }
                {
                    this.state.updateComplete ?
                        this.state.formSuccess ?

                            <Message
                                success
                                header='Payment method updated!'
                                content={`Your prior card information has been removed from your account.`}
                            />
                        :
                            <Message
                                success
                                header='Payment Processed'
                                content="Thank you for your business!"
                            />
                    :
                        null
                }
            </Form>


        );
    }
}

export default injectStripe(CheckoutForm);
