/**
 * Created by salprincipato on 5/31/17.
 */
import React from 'react';

import Networking from './networking'


const endpointBase = Networking.networkSetup('production')


export default class TaskService {


    constructor() {

    }


    static getCount (channel,tasks) {

        let count = 0
        tasks.forEach((t)=>{
            if (t.taskchannel && t.taskchannel.length && t.taskchannel[0].uuid === channel.uuid) {
                count++
            }
        })

        return count


    }
    /** Create a Task **/
    static async create(data) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/task`,{
            method: "POST",
            headers: await Networking.getHeader(),
            body: JSON.stringify(data)
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }


    /** update Task  **/
    static async update(uuid,data) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/task?uuid=${uuid}`,{
            method: "PUT",
            headers: await Networking.getHeader(),
            body: JSON.stringify(data)
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** get All Tasks by Punchlist  **/
    static async getByPunchlist(punchlistUUID,limit,offset) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/task?punchlistUUID=${punchlistUUID}&limit=${limit}&offset=${offset}`,{
            method: "GET",
            headers: await Networking.getHeader()
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** get Completed Tasks by Punchlist  **/
    static async getCompleteByPunchlist(punchlistUUID,limit,offset) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/task?complete=true&punchlistUUID=${punchlistUUID}&limit=${limit}&offset=${offset}`,{
            method: "GET",
            headers: await Networking.getHeader()
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** get not completed Tasks by Punchlist  **/
    static async getIncompleteByPunchlist(punchlistUUID,limit,offset) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/task?incomplete=true&punchlistUUID=${punchlistUUID}&limit=${limit}&offset=${offset}`,{
            method: "GET",
            headers: await Networking.getHeader()
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** search Tasks by channel  **/
    static async searchByChannel(uuid,search,limit,offset) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/task?search=${search}&channelUUID=${uuid}&limit=${limit}&offset=${offset}`,{
            method: "GET",
            headers: await Networking.getHeader()
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }


    /** get Task by Job **/
    static async getFiles(uuid,limit,offset) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/task/files?uuid=${uuid}&limit=${limit}&offset=${offset}`,{
            method: "GET",
            headers: await Networking.getHeader()
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }


    /** add Task to Job **/
    static async addFiles(taskUUID,fileUUIDs) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/task/files/bulk`,{
            method: "PUT",
            headers: await Networking.getHeader(),
            body:JSON.stringify({taskUUID:taskUUID,uuids:fileUUIDs})
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** remove Task to Job **/
    static async removeFiles(taskUUID,fileUUIDs) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/task/files/bulk?remove=true`,{
            method: "PUT",
            headers: await Networking.getHeader(),
            body:JSON.stringify({taskUUID:taskUUID,uuids:fileUUIDs})
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

}
