import React, {Component} from 'react'
import { Menu, Statistic,Card, Button,Rating, Label, Container, Dropdown, Segment, Header, Checkbox, Icon, Image, Grid, List, Item, Divider, Modal, Loader, Dimmer, Form, Message} from 'semantic-ui-react'

// import DateTime from 'react-datetime'
import moment from 'moment'
import StripeService from "../../services/stripe";
import UtilitiesService from "../../services/utilities";
// import 'react-datepicker/dist/react-datepicker.css';
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';
const logo = '/img/IMG_0632.PNG'


export default class Invoices extends Component {

  constructor(props) {
      super(props)

      this.state = {
          loading:false,
          complete:false,
          limit:100,
          offset:0,
          // venue:props.venue,
          //userData:props.userData,
          showModal:false,
          mobile:UtilitiesService.mobileCheck(),
          // cardView:props.cardView,
          invoices:[]
      }
  }




    getInvoices() {

      if (this.state.loading) return
      this.setState({loading:true})


      //get venue invoices
        return StripeService.getCharges(this.state.limit,this.state.offset)
            .then((invoices) => {

                let newInvoices = this.state.invoices.concat(invoices)

                this.setState({invoices:newInvoices,complete:!invoices || invoices.length < this.state.limit,loading:false})
            })
            .catch((err)=>{
                this.setState({loading:false})
            })

    }



    render(){

        return (
            <Modal size='small' onOpen={()=>{this.setState({offset:0,invoices:[]},()=>this.getInvoices())}} trigger={
                <Button basic as={'a'} content={'Invoices'} onClick={()=>this.setState({showModal:true})} />
            } open={this.state.showModal}>
                <Label attached={'top right'} size={'large'} style={{cursor:'pointer'}} icon={'close'} onClick={()=>this.setState({showModal:false})} />
                <Modal.Header>Invoices</Modal.Header>
                <Modal.Content scrolling>
                    <Container>
                        <Dimmer active={this.state.loading}>
                            <Loader>Gathering Invoices...</Loader>
                        </Dimmer>

                        {
                            this.state.invoices.length && !this.state.loading ?
                                <List size={'large'} divided verticalAlign='middle'>
                                    {
                                        this.state.invoices.map((invoice) => {
                                            return <List.Item as={'a'} style={{paddingTop:15,paddingBottom:15}} href={invoice.receipt_url} target={"_blank"}>
                                                <Icon name='external'/>
                                                <List.Content>
                                                    <List.Header as='a'>${UtilitiesService.numberWithCommas((invoice.amount / 100).toFixed(2))}</List.Header>
                                                    <List.Description>
                                                        {invoice.description}
                                                        <br/>
                                                        {/*<a href={invoice.} />*/}
                                                        <Label basic content={`Posted ${moment(invoice.createdAt).format('MM/DD/YYYY')}`}/>
                                                        <Label style={{marginTop:15}} compact content={invoice.paid ? `Paid` : 'Pending Payment'} color={invoice.paid ? 'green' : 'blue'} />
                                                    </List.Description>
                                                </List.Content>
                                            </List.Item>

                                        })
                                    }
                                </List>
                            :
                                <Message color={'blue'} size={'mini'} style={{textAlign:'center',marginTop:25,marginBottom:25,maxWidth:350}}>
                                    You have not yet been billed for any services.
                                </Message>
                        }
                    </Container>
                </Modal.Content>
            </Modal>

        )
  }
}
