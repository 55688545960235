/**
 * Created by salprincipato on 5/31/17.
 */
import React from 'react';

import Networking from './networking'


const endpointBase = Networking.networkSetup('production')


export default class JobService {
    //
    // static endpointBase = Networking.networkSetup('production')

    constructor() {
        this.state = {
            // endpointBase:Networking.networkSetup('production')
        }

    }




    /** Create a Job **/
    static async create(data) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/job`,{
            method: "POST",
            headers: await Networking.getHeader(),
            body: JSON.stringify(data)
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** GET NEARBY  **/
    static async getNearby(dist,longitude,latitude) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/job/nearby?miles=${dist}&longitude=${longitude}&latitude=${latitude}`,{
            method: "GET",
            headers: await Networking.getHeader()
        })
            .then((res) => {
                if (!res.ok) {
                    // throw Error(res);
                }
                return res.json()
            })
            .catch((err) => {
                // throw Error(err)
            })
    }


    /** get job  **/
    static async getByUUID(uuid) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/job?uuid=${uuid}`,{
            method: "GET",
            headers: await Networking.getHeader()
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }


    /** check if user is a member  **/
    static async isMember(uuid) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/job/already/member?uuid=${uuid}`,{
            method: "GET",
            headers: await Networking.getHeader()
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** get client users in channel  **/
    static async getClients(uuid,limit,offset) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/job/clients?uuid=${uuid}&limit=${limit}&offset=${offset}`,{
            method: "GET",
            headers: await Networking.getHeader()
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }


    /** get members of job **/
    static async getMembers(uuid,limit,offset) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/job/members?uuid=${uuid}&limit=${limit}&offset=${offset}`,{
            method: "GET",
            headers: await Networking.getHeader()
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** get members of job **/
    static async getAdmins(uuid,limit,offset) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/job/admins?uuid=${uuid}&limit=${limit}&offset=${offset}`,{
            method: "GET",
            headers: await Networking.getHeader()
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** get check if admin of job **/
    static async isAdmin(uuid) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/job/user/admin?uuid=${uuid}`,{
            method: "GET",
            headers: await Networking.getHeader()
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }



    /** update job  **/
    static async update(data,uuid) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/job?uuid=${uuid}`,{
            method: "PUT",
            headers: await Networking.getHeader(),
            body: JSON.stringify(data)
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** Bulk add admins to job **/
    static async bulkAddAdmin(jobUUID,userUUIDs) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/job/admin/bulk`,{
            method: "PUT",
            headers: await Networking.getHeader(),
            body: JSON.stringify({
                jobUUID:jobUUID,
                userUUIDs:userUUIDs
            })

        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }


    /** Bulk remove admins to job **/
    static async bulkRemoveAdmin(jobUUID,userUUIDs) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/job/admin/bulk?remove=true`,{
            method: "PUT",
            headers: await Networking.getHeader(),
            body: JSON.stringify({
                jobUUID:jobUUID,
                userUUIDs:userUUIDs
            })

        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }


    /** Bulk add clients to job **/
    static async bulkAddClient(jobUUID,userUUIDs) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/job/client/bulk`,{
            method: "PUT",
            headers: await Networking.getHeader(),
            body: JSON.stringify({
                jobUUID:jobUUID,
                userUUIDs:userUUIDs
            })

        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** Bulk remove clients to job **/
    static async bulkRemoveClient(jobUUID,userUUIDs) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/job/client/bulk?remove=true`,{
            method: "PUT",
            headers: await Networking.getHeader(),
            body: JSON.stringify({
                jobUUID:jobUUID,
                userUUIDs:userUUIDs
            })

        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** Generate ClientHash for Job **/
    static async generateClientHash(jobUUID) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/job/generate/clienthash?uuid=${jobUUID}`,{
            method: "PUT",
            headers: await Networking.getHeader()
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** Generate JoinKey for Job **/
    static async generateJoinKey(jobUUID) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/job/generate/joinkey?uuid=${jobUUID}`,{
            method: "PUT",
            headers: await Networking.getHeader()
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }


    /** add Channel to Job **/
    static async addChannel(jobUUID,channelUUID) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/job/channel/addChannel?uuid=${jobUUID}&channelUUID=${channelUUID}`,{
            method: "PUT",
            headers: await Networking.getHeader()
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** remove Channel to Job **/
    static async removeChannel(jobUUID,channelUUID) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/job/channel/removeChannel?uuid=${jobUUID}&channelUUID=${channelUUID}`,{
            method: "PUT",
            headers: await Networking.getHeader()
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** get Files by Job **/
    static async getFiles(uuid,limit,offset) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/job/files?uuid=${uuid}&limit=${limit}&offset=${offset}`,{
            method: "GET",
            headers: await Networking.getHeader()
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }


    /** add Files to Job **/
    static async addFiles(jobUUID,fileUUIDs) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/job/files/bulk`,{
            method: "PUT",
            headers: await Networking.getHeader(),
            body:JSON.stringify({jobUUID:jobUUID,uuids:fileUUIDs})
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** remove Files to Job **/
    static async removeFiles(jobUUID,fileUUIDs) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/job/files/bulk?remove=true`,{
            method: "PUT",
            headers: await Networking.getHeader(),
            body:JSON.stringify({jobUUID:jobUUID,uuids:fileUUIDs})
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** create a new location for a Job  **/
    static async setLocation(jobUUID,data) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/job/location?uuid=${jobUUID}`,{
            method: "PUT",
            headers: await Networking.getHeader(),
            body: JSON.stringify(data)
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** get job by key **/
    static async getByKey(string) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/job/joinkey?string=${string}`,{
            method: "GET",
            headers: await Networking.getHeader()
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** get job by client hash **/
    static async getByClientHash(string) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/job/clienthash?string=${string}`,{
            method: "GET",
            headers: await Networking.getHeader()
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** join a Job **/
    static async join(uuid) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/job/join?uuid=${uuid}`,{
            method: "PUT",
            headers: await Networking.getHeader()
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** leave a Job **/
    static async leave(uuid) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/job/leave?uuid=${uuid}`,{
            method: "PUT",
            headers: await Networking.getHeader()
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }


    /** join as a client a Job **/
    static async joinAsClient(uuid) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/job/join/client?uuid=${uuid}`,{
            method: "PUT",
            headers: await Networking.getHeader()
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** leave as a client a Job **/
    static async leaveAsClient(uuid) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/job/leave/client?uuid=${uuid}`,{
            method: "PUT",
            headers: await Networking.getHeader()
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }


}