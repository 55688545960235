import React, {Component} from 'react'
import { Menu, Button, Popup, Container, Segment, Card, Tab,Header, Icon, Image, Label, Grid, List, Item, Divider, Modal, Loader, Dimmer, Form, Message} from 'semantic-ui-react'
import moment from 'moment'
import StripeCheckoutPurchaseContainer from "../StripeCheckoutPurchaseContainer";
import StripeCustomerUpdate from "../StripeCustomerUpdate";
import UtilitiesService from "../../services/utilities";
import StripeService from "../../services/stripe";
export default class PaymentContainer extends Component {

  constructor(props) {
      super(props)


      this.state = {
          showModal:false,
          showCancelAlert:false,
          upcomingUpdateVenueTier:null,
          upcomingCreateSubscriptionTask:null,
          mobile:UtilitiesService.mobileCheck(),
          hideCancel:false,
          showModalCC:false,
          updatePaymentModal:false,
          customerToUpdate:null,
          reviewPayment:false,
          activeIndex:0,
          sitesToBuy:1
          // parentModalOpen:props.parentModalOpen,
          // selectedItemModal:props.selectedItemModal
          //updateParentState:props.updateParentState()
      }



  }


  handleTabChange = (e, { activeIndex }) => this.setState({ activeIndex })
  componentDidMount() {

      // this.props.getCustomers && this.props.getCustomers()
      // setTimeout(()=>{
      //     this.setState({showModal:true})
      // },1500)
  }
    changeSites = (event) => {
        this.setState({
            sitesToBuy:event.target.value ? parseInt(event.target.value) : 0
        })
    }

    render(){


        let game = this.props.game

        return (
            <div>

                <Modal open={this.props.parentModalOpen} onOpen={()=>this.props.getCustomers && this.props.getCustomers()} onClose={()=>this.props.updateParentState && this.props.updateParentState({parentModalOpen:false,gameInView:null})}  size='tiny' closeIcon>
                    <Modal.Header>{'Select a Payment Method'}</Modal.Header>
                    <Modal.Content scrolling>

                        <div>
                            {/*<Header content={'Select a Payment Method'}/>*/}
                            <Tab onTabChange={this.handleTabChange} activeIndex={this.state.activeIndex} panes={
                                [{ menuItem: 'Your cards', render: () => <Tab.Pane>

                                        <List style={{marginTop:25}} divided relaxed>
                                            {
                                                this.props.customers.length === 0 ?
                                                    <Message header={'No card on file.'} content={'Add a new card to your account.'} />
                                                    :
                                                    null
                                            }
                                            {
                                                this.props.customers.length ?

                                                    this.props.customers.map((customer) =>{
                                                        return <List.Item key={customer.uuid} style={{paddingTop:10,paddingBottom:10}}>

                                                            <List.Icon name='credit card' size='large' verticalAlign='middle' />
                                                            <List.Content>
                                                                <List.Header as={'h3'}>{customer.name}</List.Header>
                                                                <List.Header as={'h4'}>{customer.brand} - {customer.last4}</List.Header>
                                                                <List.Description style={{marginBottom:10}}>
                                                                    Exp. {customer.exp_month}/{customer.exp_year}
                                                                    <br/>
                                                                    Last Updated: {moment(customer.updatedAt || new Date()).format('MMMM Do YYYY @ h:mm a')}

                                                                </List.Description>
                                                                <Button size={'small'} content={'Update'} compact={true} basic primary onClick={()=>this.setState({updatePaymentModal:true,customerToUpdate:customer})}/>
                                                                <Modal trigger={<Button size={'small'} content={'Select'} compact={true} primary/>}  size='tiny' closeIcon>
                                                                    <Modal.Header>{this.props.pageHeader ? this.props.pageHeader : 'Make a Payment'}</Modal.Header>
                                                                    <Modal.Content scrolling>
                                                                        <Form size={'huge'} key={'huge'}>
                                                                            <Form.Group widths='equal'>
                                                                                <Form.Field
                                                                                    label='How many Sites do you want to purchase?'
                                                                                    control='input'
                                                                                    placeholder='# of Sites'
                                                                                    onChange={this.changeSites}
                                                                                    value={this.state.sitesToBuy}
                                                                                />
                                                                            </Form.Group>
                                                                            <Divider />
                                                                        </Form>
                                                                        <Card style={{margin:'auto',marginTop:25}}>
                                                                            <Card.Content>
                                                                                <Card.Header>Invoice</Card.Header>
                                                                            </Card.Content>
                                                                            <Card.Content>
                                                                                {/*<Card.Meta>Attempted on {moment(this.state.invoice.updatedAt).format('MMMM Do YYYY @ h:mm a')}</Card.Meta>*/}
                                                                                <Header as={'h4'} style={{marginBottom:5,paddingBottom:0}}>Invoice Description</Header>
                                                                                <Card.Description style={{marginBottom:10}}>
                                                                                    <List>
                                                                                        <List.Item>
                                                                                            <Header as={'h4'} content={`${this.state.sitesToBuy} ${this.state.sitesToBuy === 1 ? 'Site' : 'Sites'} x $250`} />
                                                                                        </List.Item>
                                                                                        <List.Item>
                                                                                            <Header as={'h4'} content={`= $${UtilitiesService.numberWithCommas(this.state.sitesToBuy*250)}`} />
                                                                                        </List.Item>
                                                                                    </List>

                                                                                </Card.Description>
                                                                                <Card.Description style={{marginBottom:10}}>
                                                                                    <Header as={'h4'} style={{marginBottom:5,paddingBottom:0}}>Card Info</Header>
                                                                                    <strong>{customer.brand} - {customer.last4}</strong>
                                                                                    <p>expiration date {customer.exp_month}/{customer.exp_year}</p>
                                                                                </Card.Description>

                                                                                {/*<Header as={'h4'} style={{marginBottom:5,paddingBottom:0}}>Invoice Details</Header>*/}

                                                                            </Card.Content>
                                                                        </Card>
                                                                        <p style={{alignSelf:'center',margin:'auto',maxWidth:300,textAlign:'center',marginTop:25}}>
                                                                            To make a payment of <strong>${UtilitiesService.numberWithCommas(this.state.sitesToBuy*250)}</strong> USD to purchase <strong>{this.state.sitesToBuy} {this.state.sitesToBuy === 1 ? 'Site' : 'Sites'}</strong> please click "Confirm Order" below.
                                                                        </p>
                                                                    </Modal.Content>

                                                                    <Modal.Actions>
                                                                        <Button loading={this.props.processingPayment} disabled={this.state.sitesToBuy <= 0 } positive content={`Confirm Order`} onClick={()=>{
                                                                            this.props.makePurchase && this.props.makePurchase({customer:customer,sitesToBuy:this.state.sitesToBuy})
                                                                        }} />
                                                                    </Modal.Actions>
                                                                </Modal>
                                                                {/*{*/}
                                                                {/*this.checkForFailedAttempt(customer) ?*/}
                                                                {/*<Message size={'small'} color={'red'} icon={'exclamation'} header={'Update payment information.'} content={'The last payment attempt failed using this card. Please update or remove this payment method.'} />*/}
                                                                {/*:*/}
                                                                {/*null*/}
                                                                {/*}*/}
                                                            </List.Content>
                                                        </List.Item>

                                                    })
                                                    :
                                                    null
                                            }
                                        </List>
                                    </Tab.Pane> },
                                    { menuItem: 'Add a card', render: () => <Tab.Pane>
                                        <StripeCheckoutPurchaseContainer getCustomers={this.props.getCustomers} updateActiveIndex={()=>this.setState({activeIndex:0})} makePurchase={this.props.makePurchase} userData={this.props.userData} updateParentState={this.props.updateParentState} />
                                    </Tab.Pane> }]
                            } />
                        </div>

                    </Modal.Content>

                </Modal>


                <Modal open={this.state.updatePaymentModal} onClose={()=>this.setState({customerToUpdate:null,updatePaymentModal:false})} size='tiny' closeIcon>
                    <Modal.Header>Update Payment Method</Modal.Header>
                    <Modal.Content>
                        {
                            this.state.customerToUpdate ?
                                <StripeCustomerUpdate customer={this.state.customerToUpdate} ticketPurchase={this.props.ticketPurchase} getCustomers={this.props.getCustomers} userData={this.props.userData} updateParentState={(state)=>this.setState(state)} game={this.props.game} />
                            :
                                null
                        }
                    </Modal.Content>
                </Modal>
            </div>


        )
    }
}
