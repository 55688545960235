/**
 * Created by salprincipato on 5/31/17.
 */
import React from 'react';

import Networking from './networking'


const endpointBase = Networking.networkSetup('production')

export default class ChannelService {

    // static endpointBase = Networking.networkSetup('production')

    constructor() {
        this.state = {
            // endpointBase:Networking.networkSetup('production')
        }
    }




    /** Create a Channel **/
    static async create(data) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/channel`,{
            method: "POST",
            headers: await Networking.getHeader(),
            body: JSON.stringify(data)
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** generate a key for a channel **/
    static async generateJoinKey(uuid) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/channel?generateJoinKey=true`,{
            method: "PUT",
            headers: await Networking.getHeader(),
            body: JSON.stringify({uuid:uuid})
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** update channel  **/
    static async update(uuid,data) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/channel?uuid=${uuid}`,{
            method: "PUT",
            headers: await Networking.getHeader(),
            body: JSON.stringify(data)
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }


    /** get channel  **/
    static async getByUUID(uuid) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/channel?uuid=${uuid}`,{
            method: "GET",
            headers: await Networking.getHeader()
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** get channel by jobUUID **/
    static async getByJobUUID(uuid,limit,offset) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/channel?jobUUID=${uuid}&limit=${limit}&offset=${offset}`,{
            method: "GET",
            headers: await Networking.getHeader()
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }





    /** get active users in channel  **/
    static async getActiveUsers(uuid) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/channel/users?active=true&uuid=${uuid}`,{
            method: "GET",
            headers: await Networking.getHeader()
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** get active client channels  **/
    static async getClientChannels(uuid) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/channel/client?jobUUID=${uuid}`,{
            method: "GET",
            headers: await Networking.getHeader()
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }


    /** get banned users in channel  **/
    static async getBannedUsers(uuid,limit,offset) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/channel/users?banned=true&uuid=${uuid}&limit=${limit}&offset=${offset}`,{
            method: "GET",
            headers: await Networking.getHeader()
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** get admin users in channel  **/
    static async getAdminUsers(uuid,limit,offset) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/channel/users?admin=true&uuid=${uuid}&limit=${limit}&offset=${offset}`,{
            method: "GET",
            headers: await Networking.getHeader()
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** join a Channel **/
    static async join(uuid) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/channel/join?uuid=${uuid}`,{
            method: "PUT",
            headers: await Networking.getHeader()
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** Bulk join a channel by job **/
    static async bulkJoin(jobUUID,channelUUID) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/channel/join/bulk`,{
            method: "PUT",
            headers: await Networking.getHeader(),
            body: JSON.stringify({
                jobUUID:jobUUID,
                channelUUID:channelUUID
            })

        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** Bulk add admins to channel **/
    static async bulkAddAdmin(jobUUID,channelUUID,userUUIDs) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/channel/admin/bulk`,{
            method: "PUT",
            headers: await Networking.getHeader(),
            body: JSON.stringify({
                jobUUID:jobUUID,
                channelUUID:channelUUID,
                userUUIDs:userUUIDs
            })

        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** Bulk remove admins to channel **/
    static async bulkRemoveAdmin(jobUUID,channelUUID,userUUIDs) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/channel/admin/bulk?remove=true`,{
            method: "PUT",
            headers: await Networking.getHeader(),
            body: JSON.stringify({
                jobUUID:jobUUID,
                channelUUID:channelUUID,
                userUUIDs:userUUIDs
            })

        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** Bulk add members to channel **/
    static async bulkAddMembers(jobUUID,channelUUID,userUUIDs) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/channel/member/bulk`,{
            method: "PUT",
            headers: await Networking.getHeader(),
            body: JSON.stringify({
                jobUUID:jobUUID,
                channelUUID:channelUUID,
                userUUIDs:userUUIDs
            })

        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** Bulk remove members to channel **/
    static async bulkRemoveMember(jobUUID,channelUUID,userUUIDs) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/channel/member/bulk?remove=true`,{
            method: "PUT",
            headers: await Networking.getHeader(),
            body: JSON.stringify({
                jobUUID:jobUUID,
                channelUUID:channelUUID,
                userUUIDs:userUUIDs
            })

        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** leave a Channel **/
    static async leave(uuid) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/channel/leave?uuid=${uuid}`,{
            method: "PUT",
            headers: await Networking.getHeader()
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }


    static getDirectMessageName(channel,user) {

        if (!channel.directMessage) return channel.name
        let name = user.username
        if (channel.channeluser && channel.channeluser.length) {
            channel.channeluser.forEach((u) => {
                if (u.uuid !== user.uuid) {
                    // console.log("FOUND HERE-=")
                    name = u.username
                }
            })
        }


        return name
    }

    static getDirectMessageUser(channel,user) {

        if (!channel.directMessage) return {}
        let item = user
        if (channel.channeluser.length) {
            channel.channeluser.forEach((u) => {
                if (u.uuid !== user.uuid) {
                    // console.log("FOUND HERE-=")
                    item = u
                }
            })
        }


        return item
    }

    static getDirectMessageChannelByUserUUID(channels,user) {

        if (!channels.length) return null
        let found = null
        channels.forEach((channel)=>{
            channel && channel.channeluser && channel.channeluser.forEach((u) => {
                if (u.uuid === user.uuid && user.username === channel.name) {
                    found = channel
                }
                // if (u.uuid === user.uuid && channel.channeluser.length === 1) {
                //     found = channel
                // }
            })
        })

        return found
    }

    static addDirectMessageChannelByUserUUID(channel,user) {

        if (!channel || !user) return channel
        if (!channel.directMessage) return channel
        let found = false
        channel.channeluser.forEach((u) => {
            if (u.uuid !== user.uuid) {
                channel.name = u.username
                found = true
            }
        })

        if (!found) {
            channel.name = user.username
        }

        // console.log(channel,"NEW CHANNEL NAME")

        return channel
    }

    static checkIfSelectedDirectMessage(channels,selectedChannel,user) {
        if (!channels.length) return false
        let found = false
        channels.forEach((c) => {
            c.channeluser.forEach((u) => {
                if (u.uuid === user.uuid && c.name === user.username) {
                    found = c
                }
                // if (u.uuid === user.uuid && c.channeluser.length === 1) {
                //     found = c
                // }
            })
        })

        if (found.uuid === selectedChannel.uuid) return true

        return false
    }


    /** get Files by Channel **/
    static async getFiles(uuid,limit,offset) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/channel/files?uuid=${uuid}&limit=${limit}&offset=${offset}`,{
            method: "GET",
            headers: await Networking.getHeader()
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }


    /** add Files to Channel **/
    static async addFiles(channelUUID,fileUUIDs) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/channel/files/bulk`,{
            method: "PUT",
            headers: await Networking.getHeader(),
            body:JSON.stringify({channelUUID:channelUUID,uuids:fileUUIDs})
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** remove Files to Channel **/
    static async removeFiles(channelUUID,fileUUIDs) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/channel/files/bulk?remove=true`,{
            method: "PUT",
            headers: await Networking.getHeader(),
            body:JSON.stringify({channelUUID:channelUUID,uuids:fileUUIDs})
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }


}