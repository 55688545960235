/**
 * Created by salprincipato on 5/31/17.
 */
import React from 'react';

import Networking from './networking'


const endpointBase = Networking.networkSetup('production')

export default class PostService {

    // static endpointBase = Networking.networkSetup('production')

    constructor() {
        this.state = {
            // endpointBase:Networking.networkSetup('production')
        }
    }

    /** Create a Post **/
    static async create(data) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/post`,{
            method: "POST",
            headers: await Networking.getHeader(),
            body: JSON.stringify(data)
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }


    /** Create a Post with Files **/
    static async createWithFiles(data) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/post/files?task=true`,{
            method: "POST",
            headers: await Networking.getHeader(),
            body: JSON.stringify(data)
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }


    /** Create a Postcomment **/
    static async createComment(data,uuid) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/post/comment?uuid=${uuid}`,{
            method: "POST",
            headers: await Networking.getHeader(),
            body: JSON.stringify(data)
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }


    /** update Post  **/
    static async update(data,uuid) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/post?uuid=${uuid}`,{
            method: "PUT",
            headers: await Networking.getHeader(),
            body: JSON.stringify(data)
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** send post through socket  **/
    static async sendSocketNew(channelUUID,postUUID) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/post/socket?new=true`,{
            method: "PUT",
            headers: await Networking.getHeader(),
            body:JSON.stringify({
                channelUUID:channelUUID,
                newPostUUID:postUUID
            })
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** send post through socket  **/
    static async sendSocketReply(newPostUUID,parentPostUUID) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/post/socket?reply=true`,{
            method: "PUT",
            headers: await Networking.getHeader(),
            body:JSON.stringify({
                newPostUUID:newPostUUID,
                parentPostUUID:parentPostUUID
            })
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** update Post With Files **/
    static async updateWithFiles(data,uuid) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/post/files?task=true&uuid=${uuid}`,{
            method: "PUT",
            headers: await Networking.getHeader(),
            body: JSON.stringify(data)
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** get Files by Post **/
    static async getFiles(uuid,limit,offset) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/post/files?uuid=${uuid}&limit=${limit}&offset=${offset}`,{
            method: "GET",
            headers: await Networking.getHeader()
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }


    /** add Files to Post **/
    static async addFiles(postUUID,fileUUIDs) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/post/files/bulk`,{
            method: "PUT",
            headers: await Networking.getHeader(),
            body:JSON.stringify({postUUID:postUUID,uuids:fileUUIDs})
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** remove Files to Post **/
    static async removeFiles(postUUID,fileUUIDs) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/post/files/bulk?remove=true`,{
            method: "PUT",
            headers: await Networking.getHeader(),
            body:JSON.stringify({postUUID:postUUID,uuids:fileUUIDs})
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** get Posts by channel  **/
    static async getByChannel(uuid,limit,offset,order) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/post?byChannel=true&uuid=${uuid}&limit=${limit}&offset=${offset}&order=${order}`,{
            method: "GET",
            headers: await Networking.getHeader()
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** search Posts by channel  **/
    static async searchByChannel(uuid,search,limit,offset) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/post?search=${search}&channelUUID=${uuid}&limit=${limit}&offset=${offset}`,{
            method: "GET",
            headers: await Networking.getHeader()
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** get Postcomments by Post  **/
    static async getPostComments(uuid,limit,offset) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/post/comments?uuid=${uuid}&limit=${limit}&offset=${offset}`,{
            method: "GET",
            headers: await Networking.getHeader()
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** Add a Task to a Post **/
    static async addTask(uuid,taskUUID) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/post/add/task?uuid=${uuid}&taskUUID=${taskUUID}`,{
            method: "PUT",
            headers: await Networking.getHeader()
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()
            })
            .catch((err) => {
                throw Error(err)
            })
    }


}