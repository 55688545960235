/**
 * Created by salprincipato on 5/31/17.
 */
import React from 'react';

import Networking from './networking'


const endpointBase = Networking.networkSetup('production')


export default class StripeService {

    // static endpointBase = Networking.networkSetup('production')

    constructor() {
        this.state = {
            // endpointBase:Networking.networkSetup('production')
        }
    }


    /** Get PK **/
    static async getPk() {

        // return {key:'pk_test_EIfN5U9XfrIa8kWjUr0YKf8o00jTTIDbRX'}

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/stripe/action/pk`,{
            method: "GET",
            headers: await Networking.getHeader(),
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** Create a Customer **/
    static async createCustomer(data) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/stripe/action/create/customer`,{
            method: "POST",
            headers: await Networking.getHeader(),
            body: JSON.stringify(data)
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }


    /** Create Invoice **/
    static async createInvoice(uuid,data) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/stripe/action/create/invoice?customerUUID=${uuid}`,{
            method: "POST",
            headers: await Networking.getHeader(),
            body: JSON.stringify(data)
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** Create Charge **/
    static async createCharge(uuid,data) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/stripe/action/create/charge?customerUUID=${uuid}`,{
            method: "POST",
            headers: await Networking.getHeader(),
            body: JSON.stringify(data)
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** Get Invoices **/
    static async getInvoices(uuid,limit,offset) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/stripe/invoice/user?uuid=${uuid}&limit=${limit}&offset=${offset}`,{
            method: "GET",
            headers: await Networking.getHeader()
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    static async update(uuid,data) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/stripe/action/update/customer?uuid=${uuid}`,{
            method: "PUT",
            headers: await Networking.getHeader(),
            body: JSON.stringify(data)
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }

    /** Get Charges **/
    static async getCharges(limit,offset) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/stripe/charge/user?limit=${limit}&offset=${offset}`,{
            method: "GET",
            headers: await Networking.getHeader()
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }


    /** Get Customers **/
    static async getCustomers(uuid,limit,offset) {

        return await fetch(`${await Networking.networkSetup(endpointBase)}/v1/stripe/customer/user?uuid=${uuid}&limit=${limit}&offset=${offset}`,{
            method: "GET",
            headers: await Networking.getHeader()
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res);
                }
                return res.json()

            })
            .catch((err) => {
                throw Error(err)
            })
    }


}
