// CheckoutForm.js
import React from 'react';
import {
    Menu,
    Button,
    Container,
    Input,
    Label,
    Segment,
    Card,
    Dropdown,
    Header,
    Icon,
    Image,
    Grid,
    List,
    Item,
    Divider,
    Modal,
    Loader,
    Dimmer,
    Form,
    Message,
    Popup
} from 'semantic-ui-react'

import Stripe,{StripeProvider,injectStripe} from 'react-stripe-elements';
import CardSection from './cardSection';
import StripeService from "../../../services/stripe";
import moment from "moment/moment";

class CheckoutForm extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            cardHolderName:props.customer && props.customer.name || props.userData && props.userData.fullName || '',
            cardHolderEmail:props.customer && props.customer.email && props.customer.email.value || '',
            cardHolderPhone:props.customer && props.customer.phone && props.customer.phone.value || '',
            loading:false,
            formSuccess:false,
            loadingText:'Processing Payment...',
            formError:null,
            updateComplete:false,
            // newPaymentMethod:props.customer ? false : true,
            showCancelAlert:false,
            // updateParentState:props.updateParentState()
            //cancelSubscription:this.props.cancelSubscription(),
            // customer: props.customer
            // getUserCustomers: props.getUserCustomers()
            // updateParentFeed:updateParentFeed()
        }
    }


    componentDidMount() {

    }


    createCustomer(source) {


        return StripeService.createCustomer({
            source: source.id,
            name: this.state.cardHolderName,
            email: this.state.cardHolderEmail,
            phoneNumber: this.state.cardHolderPhone,
            // description: ``,
        })
            .then((res) => {
                if (res.err) {
                    console.log(res.err,"ERROR 122")
                    this.setState({
                        loading: false,
                        formError: res.status || 'An error occurred processing the card. Please try again.'
                    })
                    return
                }

                //send make purchase
                this.props.getCustomers && this.props.getCustomers()
                this.props.updateActiveIndex && this.props.updateActiveIndex()

            })
            .catch((err) => {
                console.log(err)
                err = JSON.stringify(err)
                console.log(err)
                setTimeout(()=>{
                    this.setState({ loading:false, formError:err.status || 'An error occurred processing the card. Please try again.' })
                },2000)
            })

    }



    handleSubmit = (ev) => {

        this.setState({
            loading:true,
            loadingText:'Processing request...'
        })

        // We don't want to let default form submission happen here, which would refresh the page.
        ev.preventDefault();



        // //CREATE
        if (!this.state.cardHolderName.length || !this.state.cardHolderEmail.length || !this.state.cardHolderPhone.length) {

            this.setState({ loading:false, formError:'Please complete the form to proceed.' })

            return
        }




        // Within the context of `Elements`, this call to createToken knows which Element to
        // tokenize, since there's only one in this group.
        this.props.stripe.createSource({
            type: 'card',
            owner: {
                name: this.state.cardHolderName,
                email:this.state.cardHolderEmail,
                phone:this.state.cardHolderPhone
            },
        })
            .then(({source}) => {

                this.createCustomer(source)

            }).catch((err)=>{
                // err = JSON.stringify(err)
                console.log(err,"ERROR")
                setTimeout(()=>{
                    this.setState({ loading:false, formError:err.status || 'Please ensure you entered a valid card and try again.' })
                },2000)
            })

    }



    render() {
        return (

            !this.state.formSuccess ?
                <Form error={this.state.formError} success={this.state.formSuccess} loading={this.state.loading} onSubmit={this.handleSubmit}>
                    {
                        this.state.formError && !this.state.loading ?
                            <Message
                                error
                                header='Error'
                                content={this.state.formError}
                            />
                        :
                            null
                    }
                    <Dimmer active={this.state.loading}>
                        <Loader>Processing your request...</Loader>
                    </Dimmer>
                    <Header as={'h4'} content={'Card Holder Information'}/>

                    <div>
                        <Form.Input fluid label='Full Name' type={'text'} value={this.state.cardHolderName} placeholder='e.g. John Doe' onChange={(event)=>{
                            this.setState({cardHolderName:event.target.value})
                        }} />
                        <Form.Input fluid label='Email' type={'email'} value={this.state.cardHolderEmail} placeholder='e.g. John@email.com' onChange={(event)=>{
                            this.setState({cardHolderEmail:event.target.value})
                        }} />

                        <Form.Input fluid label='Phone' type={'tel'} value={this.state.cardHolderPhone} placeholder='e.g. 555-555-5555' onChange={(event)=>{
                            this.setState({cardHolderPhone:event.target.value})
                        }} />
                    </div>

                    <Header as={'h4'} content={'Card Information'}/>

                    <div>
                        <CardSection />
                        <br/>
                        <br/>

                        <Button primary>
                            Add Card
                        </Button>
                    </div>
                </Form>
            :

                this.state.formSuccess ?

                    this.state.updateComplete ?

                        <Message
                            success
                            header='Payment method updated!'
                            content={`Your prior card information has been removed from your account.`}
                        />
                    :
                        <Message
                            success
                            header='Payment Processed'
                            content="Thank you for your business!"
                        />
                :
                    null
        );
    }
}

export default injectStripe(CheckoutForm);
