import React, {Component} from 'react'
import {
    Menu,
    Button,
    Input,
    Table,
    Label,
    Container,
    Embed,
    Statistic,
    Form,
    Segment,
    Header,
    Icon,
    Image,
    Grid,
    List,
    Item,
    Divider,
    Modal,
    Message
} from 'semantic-ui-react'
import {NavLink, Link} from 'react-router-dom'
import Slider from "react-slick/lib";
const tt1 = '/img/locationServices.png'

const communicationImages = [
    require('../../../../img/chat.PNG'),
    require('../../../../img/sideMenu.PNG'),
    require('../../../../img/directMessage.PNG'),
]

const createSiteImages = [
    require('../../../../img/createSite.PNG'),
    require('../../../../img/findLocationKeyboard.PNG'),
    require('../../../../img/manageGeofence.PNG'),
    require('../../../../img/joinSite.PNG'),

]

const clientImages = [
    require('../../../../img/clientTexting.PNG'),
    require('../../../../img/clientChat.PNG')

]


const taskImages = [
    require('../../../../img/createTask.PNG'),
    require('../../../../img/taskList.PNG')
]

const manageImages = [
    require('../../../../img/manageSite.PNG'),
    require('../../../../img/createCrew.PNG'),
    require('../../../../img/manageRoles.PNG'),

]

export default class MobileLayout extends Component {


    constructor(props) {
        super(props)


        this.state = {
            //mobile:props.mobile
        }

    }



    render(){

        let mobile = this.props.mobile
        return (
            <div>

                <Segment
                    textAlign='center'
                    style={{ backgroundColor:'#fff', minHeight: 700, padding: '0em 0em' }}
                    vertical
                >
                    <Grid columns={2} stackable>
                        {/*<Grid.Column>*/}
                        {/*    <Image src={require('../../../../img/joinSite.PNG')} style={{maxWidth:350,margin:'auto'}}/>*/}
                        {/*</Grid.Column>*/}
                        <Grid.Column style={{margin:'auto'}}>
                            <Button color={'blue'} circular icon='map pin' size={'massive'} style={{display:'block',margin:'auto',marginBottom:'1em'}} />

                            <span style={{textAlign:'center', fontSize:'2.5em',color:'#1d1d1d',fontWeight:'bold',lineHeight:'1em'}}>It all starts with a Site.</span>

                            <Header color={'black'} style={{fontWeight:'normal',margin:'auto',marginTop:'1em',alignSelf:'center',maxWidth:400,textAlign:'center'}} as={'h3'} content={`Each Site has a geofence to make check-in as easy as opening the Skilled On Site app.`} />

                        </Grid.Column>

                        <Grid.Column>
                            <Slider {...this.props.settings} arrows={false}>
                                {
                                    createSiteImages.map((image)=>{
                                        return <div><Image size={'medium'} centered={true} src={image} style={{maxWidth:400}}/></div>
                                    })
                                }
                            </Slider>
                        </Grid.Column>

                    </Grid>
                </Segment>

                <Segment
                    textAlign='center'
                    style={{ backgroundColor:'#fff', minHeight: 700, padding: '0em 0em' }}
                    vertical
                >
                    <Grid columns={2} stackable>

                        <Grid.Column style={{margin:'auto'}}>
                            <Button color={'blue'} circular icon='comments outline' size={'massive'} style={{display:'block',margin:'auto',marginBottom:'1em'}} />

                            <span style={{textAlign:'center', fontSize:'2.5em',color:'#1d1d1d',fontWeight:'bold',lineHeight:'1em'}}>Communicate in real-time.</span>

                            <Header color={'black'} style={{margin:'auto',marginTop:'1em',alignSelf:'center',fontWeight:'normal',maxWidth:400,textAlign:'center'}} as={'h3'} content={`Get information to all your crews at once or open a direct message to chat with a specific crew member.`} />

                        </Grid.Column>
                        <Grid.Column>
                            <Slider {...this.props.settings} arrows={false}>

                                {
                                    communicationImages.map((image)=>{
                                        return <div><Image size={'medium'} centered={true} src={image} style={{maxWidth:400}}/></div>
                                    })
                                }
                            </Slider>
                        </Grid.Column>

                    </Grid>
                </Segment>


                <Segment
                    textAlign='center'
                    style={{ backgroundColor:'#fff', minHeight: 700, padding: '0em 0em' }}
                    vertical
                >
                    <Grid columns={2} stackable>
                        <Grid.Column style={{margin:'auto'}}>
                            <Button color={'blue'} circular icon='tasks' size={'massive'} style={{display:'block',margin:'auto',marginBottom:'1em'}} />

                            <span style={{textAlign:'center', fontSize:'2.5em',color:'#1d1d1d',fontWeight:'bold',lineHeight:'1em'}}>Keep your crews on task.</span>

                            <Header color={'black'} style={{fontWeight:'normal',margin:'auto',marginTop:'1em',alignSelf:'center',maxWidth:400,textAlign:'center'}} as={'h3'} content={`Create and manage a Site punchlist to keep crews on task and monitor progress.`} />

                        </Grid.Column>


                        <Grid.Column>
                            <Slider {...this.props.settings} arrows={false}>
                                {
                                    taskImages.map((image)=>{
                                        return <div><Image size={'medium'} centered={true} src={image} style={{maxWidth:400}}/></div>
                                    })
                                }
                            </Slider>
                        </Grid.Column>

                    </Grid>
                </Segment>

                <Segment
                    textAlign='center'
                    style={{ backgroundColor:'#fff', minHeight: 700, padding: '0em 0em' }}
                    vertical
                >
                    <Grid columns={2} stackable>

                        <Grid.Column style={{margin:'auto'}}>

                            <Button color={'blue'} circular icon='lock' size={'massive'} style={{display:'block',margin:'auto',marginBottom:'1em'}} />

                            <span style={{textAlign:'center',fontSize:'2.5em',color:'#1d1d1d',fontWeight:'bold',lineHeight:'1em'}}>Manage crews and permissions.</span>

                            <Header color={'black'} style={{fontWeight:'normal',margin:'auto',marginTop:'1em',alignSelf:'center',maxWidth:400,textAlign:'center'}} as={'h3'} content={`Update your Site crews, member permissions, and punchlist items with a tap of your finger. Anywhere. Anytime.`} />

                        </Grid.Column>
                        <Grid.Column>
                            <Slider {...this.props.settings} arrows={false}>

                                {
                                    manageImages.map((image)=>{
                                        return <div><Image size={'medium'} centered={true} src={image} style={{maxWidth:400}}/></div>
                                    })
                                }
                            </Slider>
                        </Grid.Column>

                    </Grid>
                </Segment>

                <Segment
                    textAlign='center'
                    style={{ backgroundColor:'#fff', minHeight: 700, padding: '0em 0em' }}
                    vertical
                >
                    <Grid columns={2} stackable>
                        {/*<Grid.Column>*/}
                        {/*    <Image src={require('../../../../img/joinSite.PNG')} style={{maxWidth:350,margin:'auto'}}/>*/}
                        {/*</Grid.Column>*/}
                        <Grid.Column style={{margin:'auto'}}>
                            <Button color={'blue'} circular icon='handshake' size={'massive'} style={{display:'block',margin:'auto',marginBottom:'1em'}} />

                            <span style={{textAlign:'center', fontSize:'2.5em',color:'#1d1d1d',fontWeight:'bold',lineHeight:'1em'}}>Collaborate with your client.</span>

                            <Header color={'black'} style={{fontWeight:'normal',margin:'auto',marginTop:'1em',alignSelf:'center',maxWidth:400,textAlign:'center'}} as={'h3'} content={`Make it easy to keep your clients up-to-date with progress and get their input in real-time.`} />

                        </Grid.Column>

                        <Grid.Column>
                            <Slider {...this.props.settings} arrows={false}>
                                {
                                    clientImages.map((image)=>{
                                        return <div><Image size={'medium'} centered={true} src={image} style={{maxWidth:400}}/></div>
                                    })
                                }
                            </Slider>
                        </Grid.Column>

                    </Grid>
                </Segment>

                <Segment style={{ backgroundColor:'#fff', padding: '0em 0em' }} vertical>
                    <Grid columns={2} centered divided relaxed stackable>
                        <Grid.Column width={4} centered>
                            {/*<Header style={{textAlign:'center',alignSelf:'center'}} as={'h2'} content={`Get to work for just`}/>*/}
                            {/*<Statistic color={'green'} style={{alignSelf:'center',display:'block',textAlign:'center'}}>*/}
                            {/*    <Statistic.Value style={{textAlign:'center',alignSelf:'center'}}>$250</Statistic.Value>*/}
                            {/*    <Statistic.Label style={{textAlign:'center',alignSelf:'center'}}>per Site</Statistic.Label>*/}
                            {/*</Statistic>*/}
                            <div style={{textAlign:'center'}}>
                                {/*<Header style={{marginTop:'2em',textAlign:'center'}} content={'Get your first Site free!'} as={'h3'} />*/}
                                <Header style={{marginTop:'0em',textAlign:'center'}} content={'Contact us to get started!'} as={'h2'} subheader={`We'll provide your team with free training and support to make on-boarding easy.`}/>
                                <Modal open={this.props.showSalesModal} onOpen={this.props.onOpen} onClose={this.props.onClose} size={'tiny'} trigger={<Button size={'massive'} style={{alignSelf:'center'}} color={'green'} content={'Get Started'} />}>
                                    <Modal.Header>Get Skilled On Site</Modal.Header>
                                    <Modal.Content scrolling>

                                        <Modal.Description>

                                            {
                                                this.props.requestComplete ?
                                                    <Message
                                                        header={`We'll be in touch!`}
                                                        content={'One of our team members will reach out to you shortly.'}

                                                    />
                                                    :
                                                    <div>
                                                        <p>
                                                            Provide us with your contact information and we'll reach out to get you setup with Skilled On Site.
                                                        </p>
                                                        <Form>
                                                            <Form.Input
                                                                required
                                                                fluid
                                                                label='Full name'
                                                                placeholder='Full name'
                                                                onChange={this.props.onChangeName}
                                                                id='form-input-first-name'
                                                            />
                                                            <Form.Input
                                                                required
                                                                fluid
                                                                label='Email'
                                                                placeholder='Email'
                                                                onChange={this.props.onChangeEmail}
                                                            />
                                                            <Form.Input
                                                                required
                                                                fluid
                                                                label='Phone Number'
                                                                placeholder='Phone Number'
                                                                onChange={this.props.onChangePhone}
                                                            />
                                                        </Form>
                                                    </div>
                                            }

                                        </Modal.Description>
                                    </Modal.Content>
                                    <Modal.Actions>
                                        {
                                            this.props.requestComplete ?
                                                <Button primary onClick={this.props.onClose}>
                                                    Close
                                                </Button>
                                                :
                                                <Button primary onClick={this.props.submitForm}>
                                                    Submit
                                                </Button>
                                        }
                                    </Modal.Actions>
                                </Modal>

                            </div>
                        </Grid.Column>
                        <Grid.Column width={10} centered>
                            <Grid columns={2} stackable>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Button color={'blue'} circular icon='tasks' size={'massive'} style={{display:'block',margin:'auto',cursor:'default'}} />
                                        <Header content={'Manage Punchlist Items'} style={{textAlign:'center',paddingLeft:20,paddingRight:20}} subheader={'Create and manage the tasks of your crews.'}/>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Button color={'blue'} circular icon='users' size={'massive'} style={{display:'block',margin:'auto',cursor:'default'}} />
                                        <Header content={'Unlimited Users'} style={{textAlign:'center',paddingLeft:20,paddingRight:20}} subheader={`Have a big job site? No problem. Invite as many people as you'd like to the Site.`}/>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Button color={'blue'} circular icon='comments outline' size={'massive'} style={{display:'block',margin:'auto',cursor:'default'}} />
                                        <Header content={'Streamlined Communication'} style={{textAlign:'center',paddingLeft:20,paddingRight:20}} subheader={'Get the answers to the questions you have when you need them.'}/>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Button color={'blue'} circular icon='map pin' size={'massive'} style={{display:'block',margin:'auto',cursor:'default'}} />
                                        <Header content={'Easy check-in'} style={{textAlign:'center',paddingLeft:20,paddingRight:20}} subheader={'Using device location we make it easy for crews to quickly join by just opening the app.'}/>

                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Grid.Column>

                    </Grid>


                </Segment>

                <Segment style={{ backgroundColor:'#fff', padding: '4em 1em' }} vertical>
                    <Header style={{textAlign:'center',alignSelf:'center',marginBottom:'1em'}} as={'h1'} content={`Tutorial Videos`}/>
                    <Grid relaxed columns={4} stackable>
                        <Grid.Column>
                            <Embed
                                style={{borderRadius:10}}
                                id='zKGUPmE77DQ'
                                // placeholder='/images/image-16by9.png'
                                source='youtube'
                                iframe={{
                                    allowFullScreen: true,
                                }}
                                // active
                            />
                            <Header content={'Creating a Site'} style={{textAlign:'center',margin:'auto',marginTop:'1em'}} />

                        </Grid.Column>
                        <Grid.Column>
                            <Embed
                                style={{borderRadius:10}}
                                id='xMfozY0ycnI'
                                // placeholder='/images/image-16by9.png'
                                source='youtube'
                                iframe={{
                                    allowFullScreen: true,
                                }}
                                // active
                            />
                            <Header content={'Creating a Crew'} style={{textAlign:'center',margin:'auto',marginTop:'1em'}} />

                        </Grid.Column>
                        <Grid.Column>
                            <Embed
                                style={{borderRadius:10}}
                                id='F-sLfPN8yfI'
                                // placeholder='/images/image-16by9.png'
                                source='youtube'
                                iframe={{
                                    allowFullScreen: true,
                                }}
                                // active
                            />
                            <Header content={'Creating a Punchlist'} style={{textAlign:'center',margin:'auto',marginTop:'1em'}} />
                            {/*<iframe width="560" height="315" src="https://www.youtube.com/embed/F-sLfPN8yfI" frameBorder="0"*/}
                            {/*        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"*/}
                            {/*        allowFullScreen></iframe>*/}
                        </Grid.Column>
                        <Grid.Column>
                            <Embed
                                style={{borderRadius:10}}
                                id='5-4-YLNh60Y'
                                // placeholder='/images/image-16by9.png'
                                source='youtube'
                                iframe={{
                                    allowFullScreen: true,
                                }}
                                // active
                            />
                            <Header content={'Messaging'} style={{textAlign:'center',margin:'auto',marginTop:'1em'}} />

                        </Grid.Column>
                    </Grid>
                    <Header style={{textAlign:'center',marginTop:'2em'}} as={'h1'} content={'Still have questions?'} subheader={'Email us at contact@skilledonsite.com'} />
                    <div style={{justifyContent:'center',textAlign:'center'}}>
                        <Button
                            style={{margin:'auto'}}
                            as={'a'}
                            color={'blue'}
                            content={'Email Us!'}
                            size={'large'}
                            onClick={()=>window.location='mailto:contact@skilledonsite.com'}
                        />
                    </div>
                </Segment>

            </div>
        )
    }
}
